import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import http from "../utils/http";
import { gradeSystem } from "../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import { getOldRuleEngineData, getRuleEngineData } from '../features/entity/entitySlice';

const getTotal = (data: any) => {
    return data.reduce((acc: number, curr: any) => {
        if (typeof curr.marksObtained === 'number') {
            acc += curr.marksObtained
        }
        return acc
    }, 0)
}

function tableContents(linkage = false, repeatLinkage = false, ruleEngineFacts: any) {
    if (!ruleEngineFacts) return [];
    const { result: ruleEngineResult } = ruleEngineFacts || {};
    if (ruleEngineResult) {
        const { result } = ruleEngineResult
        if (!result) return [];
        const tableContent = [
            {
                id: '1(a)',
                indicator: 'Regularity of holdings meetings by SHG',
                allocatedMarks: 10,
                determinedMarks: 'No. of Meetings held x 10 / No. of Meetings required to be held as per rule of SHG',
                marksObtained: Math.round(result['meetingHolding']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'meetingHolding'
            },
            {
                id: '1(b)',
                indicator: 'Regularity of Attendance of members in the meetings',
                allocatedMarks: 10,
                determinedMarks: 'Average no. of members attended the meeting x 10 / Total no. of members of the SHG',
                marksObtained: Math.round(result['attandenceMember']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'attandenceMember'
            },
            {
                id: '2',
                indicator: 'Regularity of savings by members',
                allocatedMarks: 10,
                determinedMarks: 'Amount of Saving deposited by the members x 10 / Amount of saving required to be deposited as per rule of SHG',
                marksObtained: Math.round(result['savingsMembers']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'savingsMembers'
            },
            {
                id: '3',
                indicator: 'Velocity of lending to members from group corpus',
                allocatedMarks: 20,
                determinedMarks: 'Amount lent to the member from group corpus  / Average amount of group corpus',
                marksObtained: Math.round(result['leadingToMembersScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'leadingToMembersScore'
            },
            {
                id: '4',
                indicator: 'Regularity of repayment of loan by members',
                allocatedMarks: 20,
                determinedMarks: 'Amount of recovery against demand x 20 / Amount of demand (as per repayment schedule)',
                marksObtained: Math.round(result['repaymentOfLoan']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'repaymentOfLoan'
            },
            {
                id: '5',
                indicator: 'Record Keeping',
                allocatedMarks: 4,
                determinedMarks: 'Maintained Up to date = full Marks  Maintained but not Up to date = Half Marks Not Maintained = No Marks',
                marksObtained: Math.round(result['recordKeepingScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'recordKeepingScore'
            },
            {
                id: '6',
                indicator: `Resolution Book`,
                allocatedMarks: 8,
                determinedMarks: 'Maintained Up to date = full Marks  Maintained but not Up to date = Half Marks Not Maintained = No Marks',
                marksObtained: Math.round(result['resolutionBookScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'resolutionBookScore'
            },
            {
                id: '7',
                indicator: `Cash Book`,
                allocatedMarks: 4,
                determinedMarks: 'Maintained Up to date = full Marks  Maintained but not Up to date = Half Marks Not Maintained = No Marks',
                marksObtained: Math.round(result['cashBookScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'cashBookScore'
            },
            {
                id: '8',
                indicator: `Saving Ledger`,
                allocatedMarks: 4,
                determinedMarks: 'Maintained Up to date = full Marks  Maintained but not Up to date = Half Marks Not Maintained = No Marks',
                marksObtained: Math.round(result['savingLedgerScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'savingLedgerScore'
            },
            {
                id: '9',
                indicator: `Loan Ledger`,
                allocatedMarks: 6,
                determinedMarks: 'Maintained Up to date = full Marks  Maintained but not Up to date = Half Marks Not Maintained = No Marks',
                marksObtained: Math.round(result['loanLedgerScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'loanLedgerScore'
            },
            {
                id: '10',
                indicator: `General Ledger`,
                allocatedMarks: 4,
                determinedMarks: 'Maintained Up to date = full Marks  Maintained but not Up to date = Half Marks Not Maintained = No Marks',
                marksObtained: Math.round(result['generalLedgerScore']),
                CMAnalysis: null,
                repeatLinkage,
                linkage,
                ruleEngineResultKey: 'generalLedgerScore'
            },
            repeatLinkage && {
                id: '11(a)',
                indicator: 'No. of Transactions in last 12 months (Dr. and Cr.) in loan account',
                allocatedMarks: 10,
                determinedMarks: '12 or more = 10, 6 to 12 = 6, up to 6 = 0',
                marksObtained: 7,
                CMAnalysis: null,
                repeatLinkage,
                ruleEngineResultKey: 'noOfTransactionsIn'
            },
            repeatLinkage && {
                id: '11(b)',
                indicator: 'Servicing of interest charged in CC account',
                allocatedMarks: 10,
                determinedMarks: 'Within 1 Month = 10, Within 2 Months = 6, After 2 months = 0',
                marksObtained: 7,
                CMAnalysis: null,
                repeatLinkage,
                ruleEngineResultKey: 'interestChargedCC'
            },
            repeatLinkage && {
                id: '11(c)',
                indicator: 'Occasion of overdrawing in cc A/c due to charging of interest during last 12 months',
                allocatedMarks: 5,
                determinedMarks: 'Never = 5, On 2 Occasion = 3, More than 2 Occasion = 0',
                marksObtained: 7,
                CMAnalysis: null,
                repeatLinkage,
                ruleEngineResultKey: 'occasionsOfOverdrawing'
            },
            {
                indicator: 'Total',
                allocatedMarks: 100,
                marksObtained: 0,
                CMAnalysis: 0
            },
            {
                indicator: 'As Per System',
                allocatedMarks: 'Grade A',
                marksObtained: 'As per credit manager',
                CMAnalysis: 'Grade B'
            }
        ]

        return tableContent
    }
    return []
}


function getColumns(handleChange: any, manualCreditScoring: any, user: any) {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
        },
        {
            name: 'Indicator',
            selector: (row: any) => row.indicator,
            grow: 3,
            wrap: true,
            cell: (row: any) => (
                <>
                    {['Total', 'As Per System'].includes(row.indicator) ? <div style={{ fontWeight: 800 }}>{row.indicator}</div> : row.indicator}
                </>
            )
        },
        {
            name: 'Allocated Marks',
            selector: (row: any) => row.allocatedMarks,
            grow: 2,
            center: true,
            cell: (row: any) => (
                <>
                    {row.allocatedMarks && <div style={{ fontWeight: 800 }}>{row.allocatedMarks}</div>}
                </>
            )
        },
        {
            name: 'Formula for Determining Marks',
            selector: (row: any) => row.determinedMarks,
            grow: 5,
            wrap: true,
            center: true
        },
        {
            name: 'Marks Obtained (System)',
            selector: (row: any) => row.marksObtained,
            grow: 3,
            right: true,
            cell: (row: any) => (
                <>
                    {((row.marksObtained || row.marksObtained === 0)) && <div style={{ fontWeight: 800 }}>{row.marksObtained}</div>}
                </>
            )
        },
        {
            name: 'Credit Manager Analysis',
            selector: (row: any) => row.CMAnalysis,
            grow: 3,
            right: true,
            cell: (row: any) => (
                <>
                    {
                        (row.CMAnalysis || row.CMAnalysis === 0) ?
                            <div style={{ textAlign: 'center', fontWeight: 800 }}>{row.CMAnalysis}</div> :
                            <>
                                {manualCreditScoring.edit.includes(user?.role) && <input type='number'
                                    max={row.allocatedMarks}
                                    min={0}
                                    onChange={(e) => {
                                        if (e.target.value > row.allocatedMarks) {
                                            e.target.value = ''
                                            return;
                                        }
                                        handleChange(e.target.value, row.ruleEngineResultKey)
                                    }}
                                    style={{ width: '6rem', margin: '1rem' }}
                                />}
                            </>
                    }
                </>
            )
        }
    ]

    return { columns }
}

const conditionalRowStyles = [
    {
        when: (row: any) => !row,
        style: {
            display: 'none'
        }
    }
]

export default function Linkages({ linkage, repeatLinkage, onSave, user, ...props }: any) {


    const { manualCreditScoring } = props || {}
    const [ruleEngineFacts, setRuleEngineFacts] = useState(null);
    // const [existingRecords, setExistingRecords] = useState<any>(null)
    const [inputFields, setInputFields] = useState({})

    const dispatch = useDispatch<AppDispatch>();
    const { entity, ruleEngineData, oldRuleEngineData: existingRecords } = useSelector((state: any) => {
        return state.entity
    })

    const handleInputEvent = (value: number, key: string) => {
        setInputFields({ ...inputFields, [key]: value })
    }

    const onLinkageLoad = () => {
        if (entity?.id) {
            dispatch(getOldRuleEngineData(entity.id))
            // console.log("the result of :")
            if (!existingRecords) dispatch(getRuleEngineData({ id: entity.id, linkage }))
        }
    }

    useEffect(() => {
        onLinkageLoad()
    }, [])

    const tableContent = existingRecords && [...existingRecords] || [...tableContents(linkage, repeatLinkage, ruleEngineData)] || []
    const { columns } = getColumns(handleInputEvent, manualCreditScoring, user)

    const indexOfTotal = tableContent.findIndex((i: any) => i.indicator === 'Total')
    const indexOfGrade = tableContent.findIndex((i: any) => i.indicator === 'As Per System')
    const totalMarksObtained = getTotal(tableContent)


    const totalMarks = tableContent.reduce(
        (acc: number, curr: any) => {
            return curr && curr.allocatedMarks && !['Total', 'As Per System'].includes(curr.indicator) ? acc + parseInt(curr.allocatedMarks, 10) : acc
        }, 0
    )

    const totalAnalysisMarks = Object.values(inputFields).reduce((acc: number, curr: any) => {
        if (curr) return (acc + parseFloat(curr))
        return acc
    }, 0)

    if (indexOfTotal !== -1) {
        tableContent[indexOfTotal] = {
            indicator: 'Total',
            allocatedMarks: totalMarks,
            marksObtained: tableContent[indexOfTotal]?.marksObtained || totalMarksObtained,
            CMAnalysis: existingRecords ? tableContent[indexOfTotal].CMAnalysis : totalAnalysisMarks
        }
    }

    if (indexOfGrade !== -1) {
        tableContent[indexOfGrade] = {
            indicator: 'As Per System',
            allocatedMarks: 'Grade A',
            marksObtained: 'As per credit manager',
            CMAnalysis: gradeSystem(existingRecords ? tableContent[indexOfTotal].CMAnalysis : totalAnalysisMarks)
        }
    }

    return (
        <div style={{ marginTop: '1rem' }}>
            <h6>Details of Grading / Evaluation</h6>
            <DataTable columns={columns} conditionalRowStyles={conditionalRowStyles} dense highlightOnHover data={tableContent} />
            {
                !existingRecords &&
                 <button style={{
                    border: 'none',
                    width: '10rem',
                    height: '3rem',
                    cursor: 'pointer',
                    margin: '25px',
                }}
                    className='btn bg-gradient-success '
                    onClick={() => {
                        Object.entries(inputFields).forEach(inpf => {
                            const tableIndex = tableContent.findIndex((tc: any) => tc.ruleEngineResultKey === inpf[0]);
                            tableContent[tableIndex].CMAnalysis = inpf[1];
                        });
                        onSave(entity.id, tableContent);
                    }}
                >
                    <FontAwesomeIcon icon={faSave} /> Save
                </button>
            }
        </div>
    )
}
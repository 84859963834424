import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import entityService from './entityService'

const initialState = {
  entities: [],
  entity: null,
  ruleEngineData: null,
  oldRuleEngineData: null
}

// get entities
export const getEntities = createAsyncThunk('entities/getAll', async () => {
  const entities = await entityService.getAll()
  return entities.data
})

export const getEntity = createAsyncThunk('entities/get', async (id: any, thunkApi) => {
  try {
    const entity = await entityService.get(id)
    return entity.data
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const getRuleEngineData = createAsyncThunk('entities/getRuleEngineData', async ({ id, linkage }: any, thunkApi) => {
  try {
    const ruleEngineData = await entityService.getRuleEngineData(id, linkage)
    return ruleEngineData.data
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const getOldRuleEngineData = createAsyncThunk('entities/getOldRuleEngineData', async (id: string, thunkApi) => {
  try {
    const ruleEngineData = await entityService.getOldRuleEngineData(id)
    return ruleEngineData.data[0] || {}
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const entitySlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    reset: (state: any) => {
      state.products = []
      localStorage.removeItem('entities')
      localStorage.removeItem('currentEntity')
    },
    setEntity: (state, action) => {
      state.entity = action.payload
      localStorage.setItem('currentEntity', JSON.stringify(state.entity))
    },
    resetLinkageData: (state) => {
      state.oldRuleEngineData = null
      state.ruleEngineData = null
    },
    updateEntity: (state, action) => {
      state.entity = action.payload
      localStorage.setItem('currentEntity', JSON.stringify(action.payload))
    }
  },
  extraReducers: (builder) => {
    builder
    // get entities
      .addCase(getEntities.pending, (state) => {
        state.entities = []
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.entities = action.payload
        localStorage.setItem('entities', JSON.stringify(state.entities))
      })
      .addCase(getEntities.rejected, (state) => {
        state.entities = []
      })

    // get entity
      .addCase(getEntity.pending, (state) => {
        state.entity = null
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload
      })
      .addCase(getEntity.rejected, (state) => {
        state.entity = null
      })

    // update entity
      .addCase(getRuleEngineData.pending, (state) => {
        state.oldRuleEngineData = null
      })
      .addCase(getRuleEngineData.fulfilled, (state, action) => {
        state.oldRuleEngineData = action.payload
      })
      .addCase(getRuleEngineData.rejected, (state) => {
        state.oldRuleEngineData = null
      })

    // update entity
      .addCase(getOldRuleEngineData.pending, (state) => {
        state.ruleEngineData = null
      })
      .addCase(getOldRuleEngineData.fulfilled, (state, action) => {
        state.ruleEngineData = action.payload
      })
      .addCase(getOldRuleEngineData.rejected, (state) => {
        state.ruleEngineData = null
      })
  }
})

export const { reset, setEntity, resetLinkageData, updateEntity } = entitySlice.actions

export default entitySlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import productFormService from './productFormService'

const initialState = {
  productForms: [],
  productForm: null
}

// get productForms
export const getProductForms = createAsyncThunk('productForms/get', async () => {
  const productForms = await productFormService.getAll()
  return productForms.data
})

export const getProductForm = createAsyncThunk('productForm/get', async (id: number, thunkApi) => {
  try {
    const productForm = await productFormService.get(id)
    return productForm.data
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const productFormSlice = createSlice({
  name: 'productForms',
  initialState,
  reducers: {
    reset: (state: any) => {
      state.productForms = []
      state.productForm = null
    }
  },
  extraReducers: (builder) => {
    builder
    // get All
      .addCase(getProductForms.pending, (state) => {
        state.productForms = []
      })
      .addCase(getProductForms.fulfilled, (state, action) => {
        state.productForms = action.payload
      })
      .addCase(getProductForms.rejected, (state, action) => {
        state.productForms = []
      })

    // get One
      .addCase(getProductForm.pending, (state) => {
        state.productForm = null
      })
      .addCase(getProductForm.fulfilled, (state, action) => {
        state.productForm = action.payload
      })
      .addCase(getProductForm.rejected, (state, action) => {
        state.productForm = null
      })
  }
})

export const { reset } = productFormSlice.actions

export default productFormSlice.reducer

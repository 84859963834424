const AccessForbidden = () => {
    return (
        <main className="main-content  mt-0">
            <section className="my-8">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 my-auto">
                            <h1 className="display-1 text-bolder text-gradient text-danger">Error 403</h1>
                            <h2>Forbidden.</h2>
                            <p className="lead">Looks like you haven&apos;t got access to view this page. Please contact Admin.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AccessForbidden;
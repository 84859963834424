import './segment.css'

type TabsProps = {
  segment: {
    name: string
  },
  index: number,
  showIndex: Function,
  setShowIndex: Function,
  icon: string
}

const Tabs = ({ segment: { name }, index, showIndex, setShowIndex, icon }: TabsProps) => {
  const checkActive = (index: any, className: string) => showIndex === index && className;

  return (
    <div className='grid-container'>
      <div style={{ maxHeight: '6rem' }}>
        <button
          className={`tab btn btn-outline-default ${checkActive(index, "active")}`}
          onClick={() => setShowIndex(index)}
        >
          <a className={`nav-link`} href={`#tab-${index}`}  >
            <i className={`ni ${icon ? icon : "ni-laptop"} text-sm me-2`} ></i> {name}
          </a>
        </button>
      </div>
    </div>
  );
};

export default Tabs

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faDashboard, faEye, faPeopleGroup, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'

import './sidebar.css';

import { Link } from 'react-router-dom'
import logo from '../assets/img/givfin-white.svg'
// import logo from '../assets/img/saraswatbank.png'
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from '../app/store';
import { getProducts } from '../features/product/productSlice';
import { getSegments } from '../features/segment/segmentSlice';
import { updateMenu, updateSubmenu } from '../features/sidebar/sidebarSlice';
import './sidebar.css';
import { getUser } from '../features/user/userSlice';
import { reset } from '../features/entity/entitySlice';

function SiderBar() {

  const dispatch = useDispatch<AppDispatch>();

  const { product: { products }, user: { user: currentUser }, sidebar: { activeMenu, activeSubmenu } } = useSelector((state: any) => {
    return state
  })

  useEffect(() => {
    if (products.length === 0) dispatch(getProducts())
    if (!Object.values(currentUser)?.length) dispatch(getUser())
    dispatch(getSegments())
  }, [])

  const updateSidebarMenu = (menu: string) => {
    console.log("menu is", menu)
    dispatch(updateMenu(menu))
    if (menu === "Dashboard") dispatch(updateSubmenu(""))
  }

  const updateSidebarSubmenu = (menu: string) => {
    dispatch(updateSubmenu(menu))
  }

  const properties: any = {
    JLG: { icon: faUserGroup },
    SHG: { icon: faPeopleGroup }
  }

  return (
    <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-dark" id="sidenav-main">
      <div className="sidenav-header sticky-top bg-dark">
        <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
        <Link className="navbar-brand m-0 main-logo" to="/">
          <img src={logo} className="navbar-brand-img h-200 margin-left-30" alt="main_logo" />
        </Link>
      </div>

      <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
        <ul className={`navbar-nav`}>
          <li className={`nav-item`} onClick={() => updateSidebarMenu('Dashboard')}>
            <Link className={`nav-link ${activeMenu === 'Dashboard' && 'active'}`} to="/">
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faDashboard} />
              </div>
              <span className={`nav-link-text ms-1 ${activeMenu !== 'Dashboard' && 'text-white'}`}>Dashboard</span>
            </Link>
          </li>

          {
            products?.data && products.data.map((product: any) => (
              <li className={`nav-item  `} key={product.id} onClick={() => updateSidebarMenu(product.name)}>
                <Link className={`nav-link ${activeMenu === product.name && 'active'} dropdown-toggle `} to={`/product/${product.id}`}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon {...properties[product.name]} />
                  </div>
                  <span className={`nav-link-text ms-1 ${activeMenu !== product.name && 'text-white'}`}>{product.name}</span>
                </Link>
                <ul className={`ml-1 ${activeMenu === product.name ? 'dropdown-menu-dark' : ' dropdown-menu'}`}>
                  {
                    (product.productForms || []).map((productForm: any) => {
                      const { permissions: { edit, view }, menus } = productForm.config || {}
                      const [sidebarCreate, sidebarView] = menus
                      return (
                        <li className="nav-item dropdown-menu-start" style={{ listStyle: 'none', marginTop: '1rem' }} key={`${product.id}_${productForm.id}`}>
                          {
                            <>
                              {edit?.includes(currentUser.role) 
                              &&
                                <Link
                                  // className={`nav-link ${sidebarCreate.name === activeSubmenu && 'active'}`}
                                  className={`dropdown-item-text nav-link ${sidebarCreate.name === activeSubmenu && activeMenu === sidebarCreate.type && 'active'}`}
                                  onClick={() => {
                                    dispatch(reset())
                                    updateSidebarSubmenu(sidebarCreate.name)
                                  }
                                  }
                                  to={`/product/${product.id}/product-form/${sidebarCreate.id}/${sidebarCreate.name.toLowerCase()}`}>
                                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon icon={faAdd} />
                                  </div>
                                  <span className={`nav-link-text ms-1 ${activeSubmenu !== sidebarCreate.name ? 'text-white' : 'text-blue'}`}>{sidebarCreate.name}</span>
                                </Link>
                                }
                              {view?.includes(currentUser.role) &&
                                <Link
                                  // className={`nav-link ${sidebarView.name === activeSubmenu && 'active'}`}
                                  className={`dropdown-item-text nav-link ${sidebarView.name === activeSubmenu && activeMenu === sidebarView.type && 'active'}`}
                                  onClick={() => updateSidebarSubmenu(sidebarView.name)}
                                  to={`/product/${product.id}/product-form/${sidebarView.id}/${sidebarView.name.toLowerCase()}`}>
                                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                  <span className={`nav-link-text ms-1 ${activeSubmenu !== sidebarView.name ? 'text-white' : 'text-blue'}`}>{sidebarView.name}</span>
                                </Link>
                              }
                            </>
                          }
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            ))
          }
        </ul>
      </div>
    </aside>
  )
}

export default SiderBar
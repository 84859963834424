import http from '../../utils/http';

const getUserDetails = async () => {
    const user = await http.get('/role/validateUser');
    return user;
}

const userService = {
    getUserDetails
}

export default userService;
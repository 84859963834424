import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react'
import { AutoForm } from "uniforms";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { AutoField, ErrorField, LongTextField, SubmitField } from "uniforms-unstyled";
import { buildSchemaForCreate, createValidator, toTitleCase } from "../utils";
import ImageField from "./ImageField";
import DisplayIf from "./DisplayIf";
import Tags from "./Tags";
import DateField from "./CustomDateField";


const AutoFormComp = ({ edit, ...props }: any) => {
    const [fieldToShow, setFieldToShow] = useState({} as any)
    const { id, config, segmentMeta, segmentMetaData, currentEntity, entities, disabled, onSubmit, title, showForm, segmentMetaIdToEdit, setShowForm, showIcon = true } = props || {}
    let { schema: { schema }, conditionArr, dependsUpon } = edit ? buildSchemaForCreate(config, segmentMeta, true, segmentMetaData, currentEntity?.shgType,segmentMetaIdToEdit) : buildSchemaForCreate(config, segmentMeta, false, null, currentEntity?.shgType,segmentMetaIdToEdit)

    const bridge = new JSONSchemaBridge(schema, createValidator(schema));

    const onChange = (model: any) => {
        // if(model.ifscCode){
        // Crete an endpoint for the below api in Nest js and use it here to validate IFSC code for free
        //     try {
        //         http.get(`https://ifsc.razorpay.com/${model.ifscCode}`)
        //         .then(({ data }) => {
        //             console.log('ifscCode', data)
        //         })
        //         .catch((e) => console.log(e, 'error'))
        //     } catch (err) {
        //         console.log('err', err)
        //     }
        // }
    }

    return (
        <>
            {/*      */}

            <AutoForm
                validate="onChangeAfterSubmit"
                schema={bridge}
                disabled={disabled}
                onSubmit={(val) => {
                    onSubmit(val, segmentMeta, config, segmentMetaIdToEdit)
                }}
                // onChangeModel={model => console.log(model)}
                // onChangeModel={(value) => onChange(value)}
                // onChange={(key, value) => onChange(value)}
                >
                {
                    title &&
                    <>
        
                        <div className="grid-container">
                            <p style={{
                                margin: 0,
                                fontWeight: 800
                            }}>
                                {title}
                            </p>
                            {
                                showIcon &&
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    setShowForm(!showForm)
                                }} style={{ gridColumn: 'none', border: 'none', background: 'white' }}>
                                    <FontAwesomeIcon icon={faClose} />
                                </button>
                            }
                        </div>
                        <hr style={{ margin: 0 }} />
                    </>
                }
                <div className='grid-container'>
                    {Object.entries(schema.properties).map((p: any, ind: number) => {
                        return (
                            <>
                                <div className="grid-item" key={ind}>
                                    {
                                        
                                        !p[1].title ?
                                            <>
                                                {/* <div className="form-group">
                                                    <div className="input-group">
                                                        <input type="text" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                    </div>
                                                </div> */}
                                                    <AutoField
                                                        style={{ fontSize: '0.8rem' }}
                                                        className="form-control "
                                                        name={p[0]}
                                                        placeholder={`${toTitleCase(p[0])}`}
                                                        key={ind}
                                                    />
                                                    <ErrorField
                                                        className="text-danger text-xs p-1"
                                                        name={p[0]}
                                                        errorMessage={`${toTitleCase(p[0])} is required`}
                                                    />
                                            </> :
                                            <>
                                                {
                                                    ['image'].includes(p[1].title) ?
                                                    // <p>hey</p>
                                                        <ImageField
                                                            name={p[0]}
                                                            key={ind}
                                                            label={toTitleCase(p[0])}
                                                        /> 
                                                        :
                                                        ['date'].includes(p[1].title) ?
                                                            <DateField name={p[0]} label={p[0]} key={ind} /> :
                                                            p[1].title === "textArea" ? <LongTextField label={toTitleCase(p[0])} name={p[0]} /> :
                                                                <DisplayIf config={
                                                                    Array.isArray(schema) ?
                                                                        schema[0].properties :
                                                                        schema.properties
                                                                }
                                                                    dependsUpon={
                                                                        dependsUpon
                                                                    }
                                                                    fieldToShow={
                                                                        fieldToShow
                                                                    }
                                                                    setFieldToShow={
                                                                        setFieldToShow
                                                                    }
                                                                    conditionArr={
                                                                        conditionArr
                                                                    }
                                                                    fieldName={
                                                                        p[0]
                                                                    }
                                                                    className="form-control"
                                                                >
                                                                    <>
                                                                        <AutoField
                                                                            style={{ fontSize: '0.8rem' }}
                                                                            className="form-control"
                                                                            name={p[0]}
                                                                            key={ind}
                                                                        />
                                                                        <ErrorField
                                                                            className="text-danger text-xs p-1"
                                                                            name={p[0]}
                                                                            errorMessage={`${toTitleCase(p[0])} is required`}
                                                                        />
                                                                    </>
                                                                </DisplayIf>
                                                }
                                            </>
                                    }
                                </div>
                            </>
                        );
                    }
                    )}
                </div>
                <div className="grid-container justify-content-end">
                    <div className="grid-item">
                        <SubmitField
                            value={'Submit'}
                            className="btn btn-sm bg-gradient-secondary"
                        />
                    </div>
                </div>
            </AutoForm>
        </>
    )
}

export default AutoFormComp;

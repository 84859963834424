import http from '../../utils/http'

const getAll = async () => {
  const entities = await http.get('/entity')
  return entities
}

const get = async (id: string) => {
  const entity = await http.get(`/getEntity/${id}`)
  return entity
}

const getRuleEngineData = async (id: string, linkage: boolean) => {
  const oldRuleEngineData = await http.get(`/entity/${id}/getLinkageData/${linkage ? 'fresh' : 'mature'}`)
  return oldRuleEngineData
}

const getOldRuleEngineData = async (id: string) => {
  const newRuleEngineData = await http.get(`/entity/ruleEngine/${id}`)
  return newRuleEngineData
}

const entityService = {
  getAll,
  get,
  getRuleEngineData,
  getOldRuleEngineData
}

export default entityService

import http from '../../utils/http'

const getAll = async () => {
  const members = await http.get('/members')
  return members
}

const get = async (id: number) => {
  const member = await http.get(`/members/${id}`)
  return member
}

const memberService = {
  getAll,
  get
}

export default memberService

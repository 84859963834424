import { useEffect, useState } from "react";
import http from "../utils/http";
import AutoFormComp from "./AutoFormComp";
import Table from "./Table";
import Entity from '../types/Entity';
import Column from '../types/Column';
import { useParams } from "react-router-dom";
import Modal from "./Modal";
import OtpModal from "./OtpModal";
import Member from "../types/Member";
import CreditReport from "./CreditReport";

type SegmentProps = {
    disabled: boolean,
    id: any,
    productForm: any,
    showViewButton?: boolean,
    setMemberIdToEdit?: Function,
    entities: any,
    segmentMetaData: any,
    currentEntity: {
        id: number
    },
    edit: boolean,
    setLoading?: Function,
    onSubmit?: Function,
    config: {
        showTable: boolean,
        type: string,
        segmentConfig: any,
        subType: string
    }
}

const Segment = ({ config, onSubmit, disabled, id, showViewButton = true, productForm, setMemberIdToEdit, segmentMetaData, entities, currentEntity, edit, setLoading }: SegmentProps) => {
    const [segmentMeta, setSegmentMeta] = useState([])
    const [memberData, setMemberData] = useState([])
    const [memberId, setMemberId] = useState()
    const [member, setMember] = useState<Member>()
    const [segmentMetaIdToEdit, setSegmentMetaIdToEdit] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [otpModal, setOtpModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [creditReportData, setCreditReportData] = useState(null)

    // const mapForVariabls: Record<string, any> = {
    //     emailId: 'email',
    //     mobileNo: 'mobile',
    //     memberName: 'name',
    //     isdCode: 'isdCode'
    // }

    const [column, setColumn] = useState<Column>([{
        // segmentMetaDatumId: '',
        title: '',
        value: ''
    }])

    const [entity, setEntity] = useState({ address: [], bank: [], members: [] })
    const { entityId }: any = useParams() || {}

    useEffect(() => {
        if (id) {
            setLoading && setLoading(true);
            http.get(`/segment/${id}`)
                .then(({ data }) => {
                    setSegmentMeta(data.segmentMetas)
                    setLoading && setLoading(false);
                })
                .catch((err) => {
                    console.log("error received while getting segment data", err)
                    setLoading && setLoading(false);
                })
        }

        if (currentEntity) pullData();
    }, [])

    useEffect(() => {
        if (config.showTable) getColumnsData()
    }, [entity, memberData])

    const pullData = async () => {
        setLoading && setLoading(true);
        const entityData = await http.get(`/getEntity/${entityId || currentEntity.id}`)
        setEntity(entityData.data)
        if (config.subType === 'members') {
            const entityMember = await http.get(`/entity-members?search=${entityId || currentEntity.id}`)
            setMemberData(entityMember.data.data)
        }
        
        setLoading && setLoading(false);
    }


    const onClickEditHandler = async (rowId: number) => {

        if (config.subType === 'members' && rowId) {
            try {
                setLoading && setLoading(true);
                const { data } = await http.get(`/members/${rowId}`);
                // const memberRow = data.memberMeta.segmentMetaDatum[0];
                // const memberRow = data.memberMeta.segmentMetaDatum[0];
                
                // const sgmd = segmentMeta.filter((smd: any) => [
                //     'emailId',
                //     'mobileNo',
                //     'memberName'
                // ].includes(smd.metaField.name)).map((item: any) => ({
                //     ...memberRow,
                //     segmentMeta: item,
                //     id: Math.round(Math.random() * 100),
                //     value: data[mapForVariabls[item.metaField.name]]
                // }))
                // (data.address & data.bank) ? data.push()
                
                // data.memberMeta.segmentMetaDatum = [...data.memberMeta.segmentMetaDatum, ...sgmd]
                
                setMember({ ...data });
                if (setMemberIdToEdit) setMemberIdToEdit({ ...data })
                setLoading && setLoading(false);
            } catch (error) {
                setLoading && setLoading(false);
            }
        }    }


    const columns: { title: any; value: any; id: number }[] = []

    const getVal = (arr: any, id: number) => {
        // eslint-disable-next-line array-callback-return
        arr.map((a: any) => {
            Object.keys(a).forEach((key) => {
                columns.push({ title: key, value: a[key], id: a.id })
            })
        })
        setColumn([...columns])
    }

    const getColumnsData = () => {
        if (entity) {
            // setColumn([...entity.address])
            if (localStorage.getItem("tab") === "Addresses") {
                getVal(entity.address, 1)
            }
            else if (localStorage.getItem("tab") === "Bank Details") {
                getVal(entity.bank, 1)
            }
            else if (localStorage.getItem("tab") === "Members") {
                getVal(entity.members, 1)
            }
            // const { entityMultiMetas } = entity || {}

            // if (memberData.length && config.type === 'members') {
            //     let res: { title: string; value: unknown, id: any }[] = []
            //     memberData.map((p: any) => {
            //         const { email, mobile, name, id } = p.member || {}
            //         res = [...res, { title: 'ID', value: id, id }, { title: 'Name', value: name, id }, { title: 'Email', value: email, id }, { title: 'Phone', value: mobile, id }]
            //         return res
            //     })
            //     setColumn([...res])

            // } else if ((entityMultiMetas || []).length) {
            //     // eslint-disable-next-line array-callback-return
            //     (entityMultiMetas || []).map((entityMultiMeta: any) => {
            //         if (entityMultiMeta.segmentMetaDatum.find((p: any) => p.segmentMeta.segment.id === id)?.segmentMeta.segment.id === id) getVal(entityMultiMeta.segmentMetaDatum, entityMultiMeta.id);
            //     })
            // }

        }
    }

    const creditReportHandler = async (memberId: any) => {
        setLoading && setLoading(true);
        setMemberId(memberId);
        const segmentMetaId: any = segmentMeta.find((s: any) => s.metaField.name === "panNumber");
        const { data } = await http.post('/members/pullReport', { memberId, entityId: parseInt(entityId, 10), segmentMeta: segmentMetaId?.id })
        setLoading && setLoading(false);

        if (data[0]?.data?.status) {
            setOtpModal(true)
            const { stgOneHitId, stgTwoHitId } = data[0]?.data?.data
            localStorage.setItem('stgOneHitId', stgOneHitId)
            localStorage.setItem('stgTwoHitId', stgTwoHitId)
        }
        else if (data[0]?.data?.data?.score) {
            setShowModal(true)
            setCreditReportData(data[0]?.data?.data?.reportData)
        }
    }

    const displayMemberDetails = async (memberId: any) => { 
        setLoading && setLoading(true);
        setMemberId(memberId);
        //show member KYCs

    }

    const segmentMetaDatas = member ? member : segmentMetaData


    const currentMemberToPullData: any = memberData.find(({ member }: any) => member.id === memberId);
    let currentMemberData;
    if (currentMemberToPullData) {
        currentMemberData = currentMemberToPullData.member
    }
    return (
        <>

           
            {config.showTable ?
                <Table
                    displayConfig={config.segmentConfig}
                    columns={column}
                    creditReportHandler={(memberId: number) => creditReportHandler(memberId)}
                    onClickEdit={(returnedRowId: any) => {
                         onClickEditHandler(returnedRowId)}}

                    displayMemberDetails={(memberId: number) => displayMemberDetails(memberId)} 

                    productForm={productForm}
                    showButton
                    {
                    ...{
                        config,
                        entities,
                        segmentMetaIdToEdit,
                        setSegmentMetaIdToEdit,
                        segmentMeta,
                        onSubmit,
                        disabled,
                        id,
                        edit,
                        showForm,
                        setShowForm,
                        member,
                        setMember,
                        currentEntity,
                        showViewButton,
                        setLoading,
                    }
                    }
                    segmentMetaData={segmentMetaDatas}
                />
                 :
                <div className="col-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-1">
                            <AutoFormComp
                                {
                                ...{
                                    id,
                                    edit,
                                    config,
                                    segmentMeta,
                                    disabled,
                                    onSubmit,
                                    currentEntity,
                                    segmentMetaIdToEdit,
                                }
                                }
                                segmentMetaData={segmentMetaDatas}
                            />
                        </div>
                    </div>
                </div>
            }
            {
                otpModal &&
                <Modal show={otpModal}>
                    <OtpModal
                        {
                        ...{
                            otpModal,
                            setOtpModal,
                            currentMember: currentMemberData,
                            currentEntity
                        }
                        }
                    />
                </Modal>
            }
            {showModal && <Modal show={showModal}><CreditReport setShowModal={setShowModal} showModal={showModal} creditReportData={creditReportData} /></Modal>}
        </>
    )
}

export default Segment;
import { memo, useEffect, useState } from 'react';
import logo from '../assets/img/givfin-white.svg'
import axios from 'axios';
import Snackbar from './Snackbar';
import { useSnackbar } from '../hooks/useSnackbar';

declare global {
    interface Window {
        google: any;
    }
}

type LoginProps = {
    login: Function
  }

function Login({ login }: LoginProps) {
    const [message, setMessage] = useState('')
    const { isActive, openSnackBar } = useSnackbar()


    const handleGoogleSignIn = async ({ credential }: any) => {
        try{
            const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/role/validateGToken`, { credential });
            if (data.message) {
                setMessage(data.message)
                openSnackBar()
            } else {
                const { token } = data;
                localStorage.setItem('token', token)
                login()
            }
        } catch (err) {
            setMessage("Something went wrong!")
            openSnackBar()
        }
    }


    useEffect(() => {
        window.google?.accounts.id.initialize({
            client_id: process.env.REACT_APP_GAUTH_CLIENT_ID,
            callback: handleGoogleSignIn,
        })
        window.google?.accounts.id.renderButton(document.getElementById("g_id_signin"),
            { theme: 'outline', size: 'large', shape:'circle' })
    }, []);

    return (
        <>
            <div >
                <div>
                    <div className="row">
                        <div className="d-lg-flex d-none h-50 align-items-center pe-0 justify-content-center flex-column">
                            <div className="position-relative bg-gradient-primary h-50 border-radius-lg d-flex flex-column justify-content-center" style={{ width: '50%', padding: '7rem', top: '7rem', alignItems: 'center' }}>
                                <img src={logo} alt="main_logo" />
                                <div id="g_id_signin"
                                    className='d-lg-flex align-items-center justify-content-center'
                                    style={{ marginTop: '3rem' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar isActive={isActive} message={message} />
        </>
    );
}

export default memo(Login);

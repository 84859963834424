import http from '../../utils/http'

const getAll = async () => {
  const productForms = await http.get('/product-form')
  return productForms
}

const get = async (id: number) => {
  const product = await http.get(`/product-form/${id}`)
  return product
}

const productFormService = {
  getAll,
  get
}

export default productFormService

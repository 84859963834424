import { DateField } from 'uniforms-unstyled';

type CustomDateFieldProps = {
    name: string,
    label: string
}

function CustomDateField({ name, label }: CustomDateFieldProps) {
    // const splittedLabel = label.replace(/([A-Z])/g, " $1");
    // const finalLabel = splittedLabel.charAt(0).toUpperCase() + splittedLabel.slice(1);
    return (
        <div>
            <DateField 
                className="form-control"
                name={name} 
                label={label} 
                type='date' 
            />
        </div>
    )
}

export default CustomDateField;
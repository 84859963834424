import {useState, useEffect} from 'react'

export function useSnackbar() {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (isActive === true) {
            setTimeout(() => {
                setIsActive(false);
            }, 3000);
        }
    }, [isActive]);

    const openSnackBar = () => setIsActive(true);

    return { isActive, openSnackBar }
}
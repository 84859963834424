import { useState } from 'react'
import http from '../utils/http'
import Styles from './otpModal.module.css'

const OtpModal = ({ otpModal, setOtpModal, currentMember, currentEntity }: any) => {
    const [otpEntered, setOtpEntered] = useState({ value: '' })

    const submitHandler = async () => {
        console.log('otp submitted is', otpEntered)

        // const verificationId = localStorage.getItem('verificationId')
        const stgTwoHitId = localStorage.getItem('stgTwoHitId')
        const stgOneHitId = localStorage.getItem('stgOneHitId')
        // const body = { otp: parseInt(otpEntered.value, 10), verificationId }
        const body = { otp: parseInt(otpEntered.value, 10), entity: currentEntity, member: currentMember, stgOneHitId, stgTwoHitId }
        const response = await http.post('/verification/verifyOtp', { ...body })
        localStorage.removeItem('verificationId')
        setOtpModal(!otpModal)
    }


    return (
        <div style={{ border: '1px solid #d0d9d2', background: 'white', borderRadius: '10px', padding: '3rem' }}>
            <label>Please enter the OTP</label>
            <input type='number' value={otpEntered.value} className={Styles.digit} onChange={(e) => setOtpEntered({ value: e.target.value })} required />
            <button style={{ marginTop: '1rem' }} className="btn btn-outline-success" onClick={submitHandler}>Submit</button>
        </div>
    )
}

export default OtpModal
import { useEffect, useState } from 'react';
import http from '../utils/http';
import { useSelector } from 'react-redux';
import AutoFormFieldArray from './AutoFormFieldArray';

const AddressDetails = ({ ...props }: any) => {

    const {tab : {tab}} = useSelector((state:any)=>{
        return state
    })

    
    const [segment, setSegment] = useState(
        {
            config: {}
            ,
            segmentMetas: []
        }
    )

    useEffect(() => {
        http.get(`segment/${props.config.addressSegment}`)
            .then(({ data }) => {
                setSegment(data)

            })
            .catch((e) => console.log(e, 'error'))

    }, [props.config.addressSegment])



    return (
        <>
        {
            tab && (tab==='Members') &&
                <>
                    <AutoFormFieldArray
                        {...props}
                        title='Address Details'
                        config={segment.config}
                        type='address'
                        segmentMeta={segment?.segmentMetas}
                        memberDetails={props?.member}
                    />
                </>
        }
        </>
    )
}

export default AddressDetails;
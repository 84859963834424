import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import productService from './productService'

const localProducts = localStorage.getItem('products')

const products: any[] = localProducts && JSON.parse(localProducts)

const initialState = {
  products: products || [],
  product: null
}

// get products
export const getProducts = createAsyncThunk('products/getAll', async () => {
  const products = await productService.getAll()
  return products.data
})

export const getProduct = createAsyncThunk('products/get', async (id: number, thunkApi) => {
  try {
    const product = await productService.get(id)
    return product.data
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reset: (state: any) => {
      state.products = []
      localStorage.removeItem('products')
    }
  },
  extraReducers: (builder) => {
    builder
    // get products
      .addCase(getProducts.pending, (state) => {
        state.products = []
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload
        localStorage.setItem('products', JSON.stringify(state.products))
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.products = []
      })

    // get product
      .addCase(getProduct.pending, (state) => {
        state.product = null
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.product = null
      })
  }
})

export const { reset } = productSlice.actions

export default productSlice.reducer

import moment from 'moment'

const excludeColumns = ['CREATEDBY', 'UPDATEDBY', 'CREATEDTIME', 'UPDATEDTIME', 'STATUS', 'VERSION']

export const getColumns = (arr: any, displayConfig: any = {}) => {
  const { displayColumns, formatting } = displayConfig

  const includeColumns = displayColumns || []

  if (arr.length) {
    // content of the table
    const data = arr.reduce((acc: any, curr: any) => {
      const index = acc.findIndex((a: any) => a.id === curr.id)
      if (curr.title !== '') {
        const formatterItems = formatting && formatting[curr.title.toLowerCase()]
        const val = formatterItems ? formatValues({ ...formatterItems, value: curr.value }) : curr.value
        // console.log("val : ",val)
        const finalItem = { [curr.title.toLowerCase()]: val, id: curr.id }
        if (~index) acc[index] = { ...acc[index], ...finalItem }
        else acc.push(finalItem)
        // else acc.push({id:curr.id, "#": acc.length + 1}, finalItem  )
      }
      return acc
    }, [])

    // heading of the table
    let column = arr.filter((c: any) => {
      // console.log("includeColumns",includeColumns)
      // console.log("heading : ",c)
      // console.log("c.title",c.title)
      // if(c.title && includeColumns?.includes(c.title)){
      //   console.log("includeColumns",includeColumns)
      // }
      return c.title && includeColumns?.includes(c.title) && !excludeColumns?.includes(c.title.toUpperCase())
      // return c.title

    })

    .map((p: any) => (p.title && { name: p.title.replace(/([A-Z])/g, " $1").toUpperCase(), selector: (row: any) => row[p.title.toLowerCase()], grow: 20, wrap: true, sortable: true }))
    
    // setColumn(column)
    column = [...new Map(column.map((item: { [x: string]: any }) => [item.name, item])).values()]
    // column = [ {name: '#', selector: (row: any) => row['#'], grow: 3, wrap: true},...column]
    // console.log({ dxata, column })
    return { data, column }
  }
  return { data: [], column: [] }
}

const formatter: Record<string, any> = {
  date: ({ value, format }: any) => {
    return moment(value).format(format)
  }
}

const formatValues = ({ type, value, format }: any) => {
  return formatter[type]({ value, format })
}
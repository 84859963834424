import PropTypes from 'prop-types';

type StatusCardProps = {
    figure: any,
    label: string,
    icon: any,
    extraInfo: any
}

const StatusCard = ({ figure, label, icon, extraInfo }: StatusCardProps) => {
    return <>
        <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
                <div className="card-body p-3">
                <div className="row">
                    <div className="col-8">
                    <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">{label}</p>
                        <h5 className="font-weight-bolder mb-0">
                        {figure}
                        {' '}
                        {extraInfo}
                        </h5>
                    </div>
                    </div>
                    {
                        icon &&
                        <div className="col-4 text-end">
                            <div className="icon icon-shape bg-dark shadow text-center border-radius-md">
                                {icon}
                            </div>
                        </div>
                    }
                </div>
                </div>
            </div>
        </div>
    </>
}

StatusCard.propTypes = {
    figure: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any,
    extraInfo: PropTypes.any
}

export default StatusCard;
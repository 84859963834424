import { useParams } from 'react-router-dom'
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import { getProduct } from '../features/product/productSlice';
import ProductDashboard from '../components/ProductDashboard';

function Product() {

  const dispatch = useDispatch<AppDispatch>();
  const { productId } = useParams()

  const { product } = useSelector((state: any) => {
    return state.product
  })

  useEffect(() => {
    if (productId) {
      const prdId = parseInt(productId);
      if (!product) dispatch(getProduct(prdId))
    }
  }, [productId])

  return (
    <div className='container-fluid p-4'>
      {/* {productId} */}
      <ProductDashboard />
    </div>
  )
}

export default Product

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Dashboard from './pages/Dashboard';
import Header from './components/Header';
import SiderBar from './components/SiderBar';
import { useSelector } from 'react-redux';
import Product from './pages/Product';
import ProductForm from './pages/ProductForm';
import EntityView from './pages/EntityView';
import EntityDetailsView from './pages/EntityDetailsView';
import AccessForbidden from './components/AccessForbidden';
import Login from './components/Login';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const token = localStorage.getItem('token')
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false)

  const { product: { products }, user: { user: currentUser } } = useSelector((state: any) => {
    return state
  })

  const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

  return (
    <QueryClientProvider client={queryClient}>
      {isLoggedIn ? <div className='px-0 mx-4'>
        <Router>
          <div className="g-sidenav-show bg-gray-100">
            <SiderBar />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              <Header logout={() => setIsLoggedIn(false)}/>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                {
                  products && products.data && products.data.map((product: any) => (
                    <>
                      <Route key={product.id} path="/product/:productId" element={<Product />} />
                      {
                        product.productForms.map((productForm: any) => {
                          const showCreateField = productForm.config.permissions?.edit?.includes(currentUser.role);
                          const showViewField = productForm.config.permissions?.view?.includes(currentUser.role);
                          return (
                            <Route key={`${product.id}_${productForm.id}`} path="/product/:productId/product-form/:productFormId">
                              <Route path='/product/:productId/product-form/:productFormId/create' element={showCreateField ? <ProductForm /> : <AccessForbidden />} />
                              <Route path='/product/:productId/product-form/:productFormId/view' element={showViewField ? <EntityView /> : <AccessForbidden />} />
                              <Route path='/product/:productId/product-form/:productFormId/view/:entityId' element={<EntityDetailsView productForm={productForm} />} />
                            </Route>
                          )
                        })
                      }
                    </>
                  ))
                }
              </Routes>
            </main>
          </div>
        </Router>
        </div> : <Login login={() => setIsLoggedIn(true)} />}
    </QueryClientProvider>
  );
}

export default App;

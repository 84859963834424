import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import memberService from './memberService'

const initialState = {
  members: [],
  member: null
}

export const getMembers = createAsyncThunk('members/getAll', async () => {
  const members = await memberService.getAll()
  return members.data
})

export const getMember = createAsyncThunk('members/get', async (id: number, thunkApi) => {
  try {
    const member = await memberService.get(id)
    return member.data
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const memberSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    reset: (state: any) => {
      state.members = []
    }
  },
  extraReducers: (builder) => {
    builder
    // get members
      .addCase(getMembers.pending, (state) => {
        state.members = []
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.members = action.payload
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.members = []
      })

    // get member
      .addCase(getMember.pending, (state) => {
        state.member = null
      })
      .addCase(getMember.fulfilled, (state, action) => {
        state.member = action.payload
      })
      .addCase(getMember.rejected, (state, action) => {
        state.member = null
      })
  }
})

export const { reset } = memberSlice.actions

export default memberSlice.reducer

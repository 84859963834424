import Ajv from 'ajv'

const typeConvertToString = ['dateOfIncorporation', 'dateOfFormation', 'dateOfBirth', 'dateOfSanction', 'dateOfInspection', 'dateOfProcessing']

export function createValidator (schema: any) {
  const ajv = new Ajv({ useDefaults: true, $data: true, validateSchema: true, formats: { 'date-time': true, date: true } })
  ajv.addKeyword('uniforms')
  const validator = ajv.compile(schema)
  return (input: any) => {
    const additionalInputs = typeConvertToString.reduce((acc: any, curr: any) => {
      acc = { ...acc, [curr]: input[curr]?.toString() }
      return acc
    }, {})
    input = { ...input, ...additionalInputs }
    validator(input)
    if ((validator.errors != null) && (validator.errors.length > 0)) {
      return { details: validator.errors }
    }
  }
}

import { ReactElement } from 'react';
import './modal.css';

type ModalProps = {
    show: boolean,
    children: ReactElement 
}

const Modal = ({ show = false, children }: ModalProps) => {
    const showHideClassName = show ? "modals display-block" : "modals display-none";

    if(!show) return null

    return (
        <div className={showHideClassName}>
            <section className='modals-section'>
                {children}
            </section>
        </div>
    );
};

export default Modal;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const activeMenu = localStorage.getItem('activeMenu');
const activeSubmenu = localStorage.getItem('activeSubmenu');

const initialState: Record<string, string | null> = {
    activeMenu: activeMenu,
    activeSubmenu: activeSubmenu
};


export const updateMenu = createAsyncThunk("activeMenu/update", async (activeMenu: string, thunkApi) => {
    try {
        return activeMenu;
    } catch (err) {
        console.log(err);
        return thunkApi.rejectWithValue(err)
    }
})

export const updateSubmenu = createAsyncThunk("activeSubmenu/update", async (activeSubmenu: string, thunkApi) => {
    try {
        return activeSubmenu;
    } catch (err) {
        console.log(err);
        return thunkApi.rejectWithValue(err)
    }
})


export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.activeMenu = "Dashboard"
            state.activeSubmenu = null
            localStorage.removeItem('activeMenu');
            localStorage.removeItem('activeSubmenu');
        }
    },
    extraReducers: (builder) => {
        builder
            // get segments
            .addCase(updateMenu.pending, (state) => {
                state.activeMenu = null
            })
            .addCase(updateMenu.fulfilled, (state, action) => {
                state.activeMenu = action.payload
                localStorage.setItem('activeMenu', state.activeMenu);
            })
            .addCase(updateMenu.rejected, (state) => {
                state.activeMenu = null;
            })

            // get segment
            .addCase(updateSubmenu.pending, (state) => {
                state.activeSubmenu = null
            })
            .addCase(updateSubmenu.fulfilled, (state, action) => {
                state.activeSubmenu = action.payload
                localStorage.setItem('activeSubmenu', state.activeSubmenu);
            })
            .addCase(updateSubmenu.rejected, (state) => {
                state.activeSubmenu = null;
            })
    }
})

export const { reset } = sidebarSlice.actions;

export default sidebarSlice.reducer;
import http from '../../utils/http'

const getAll = async () => {
  const products = await http.get('/product')
  return products
}

const get = async (id: number) => {
  const product = await http.get(`/product/${id}`)
  return product
}

const productService = {
  getAll,
  get
}

export default productService

import { useRef } from 'react';
import Modal from './Modal';

type SubmissionForReviewProps = {
    visible: boolean,
    onSubmit: Function,
    onClose: any
}

const SubmissionForReview = ({ visible = false, onSubmit, onClose }: SubmissionForReviewProps) => {

    const messageRef = useRef<any>();

    const onSubmitHandler = () => {
        if(messageRef.current.value){
            onSubmit(messageRef.current.value)
        }
    }


    return <>
        <Modal show={visible}>
            <div className='card bg-white p-3'>
                <div style={{width: '400px'}} className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Submission</h5>
                            <button type="button" className="btn-close text-dark float-right text-dark">
                                <span aria-hidden="true" onClick={onClose}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={e => e.preventDefault}>
                                <div className="form-group my-4">
                                    <textarea ref={messageRef} className="form-control" placeholder='Enter Your Submission Comments here!'></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-dark float-left text-white" onClick={onSubmitHandler} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>

}

export default SubmissionForReview;


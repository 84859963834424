import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import { getProductForm } from '../features/product-form/productFormSlice';
import Segments from '../components/Segments';
import Loader from '../components/Loader';

function ProductForm() {
  const dispatch = useDispatch<AppDispatch>();

  const { productFormId } = useParams()
  const [isLoading, setLoading] = useState(false);

  const { productForm } = useSelector((state: any) => {
    return state.productForm
  })

  const { entity } = useSelector((state: any) => {
    return state.entity
  })

  useEffect(() => {
    if (productFormId) dispatch(getProductForm(parseInt(productFormId)))
  }, [])

  return (
    <>
      {
        isLoading && <Loader />
      }
      <div className='px-0 mx-4'>
        {productForm && <Segments setLoading={setLoading} entity={entity} productForm={productForm} segments={productForm.segments} />}
      </div>
    </>
  )
}

export default ProductForm

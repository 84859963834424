import http from '../../utils/http';

const getAll = async () => {
    const segments = await http.get('/segment');
    return segments;
}

const get = async (id: number) => {
    const segment = await http.get(`/segment/${id}`);
    return segment;
}

const segmentService = {
    getAll,
    get
}

export default segmentService;
import DataTable from 'react-data-table-component';
import AutoFormComp from './AutoFormComp';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose } from '@fortawesome/free-solid-svg-icons';
import { getColumns } from '../utils';
import { useNavigate, useParams } from 'react-router-dom'
import AddressDetails from './AddressDetails';
import BankDetails from './BankDetails';
import { useEffect, useMemo, useState } from 'react';
import Linkages from '../pages/Linkages';
import http from '../utils/http';
import Snackbar from './Snackbar';
import { useSnackbar } from '../hooks/useSnackbar'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import { resetLinkageData, setEntity } from '../features/entity/entitySlice';


const formStyle = {
    marginTop: '1rem',
    border: '1px solid #d0d9d2',
    background: 'white',
    borderRadius: '5px',
    margin: '1rem'
}


const Table = ({ displayConfig = {}, columns, showButton = false, productForm, showLinkageButton = false, showViewButton, showForm = false, onClickEdit, creditReportHandler, setSegmentMetaIdToEdit, segmentMetaIdToEdit, setShowForm, edit, setLoading, setMember, ...props }: any) => {
    const navigate = useNavigate()
    const { productId, productFormId } = useParams()
    const navigateUrl = `/product/${productId}/product-form/${productFormId}/view`
    let { column: tableColumns, data: tableRows } = getColumns(columns, displayConfig || []) || {}
    const [linkage, setLinkage] = useState<any>();
    const [message, setMessage] = useState("");
    const { isActive, openSnackBar } = useSnackbar();
    const { user: { user: currentUser }, tab: { tab } } = useSelector((state: any) => {

        return state
    })
    const dispatch = useDispatch<AppDispatch>();

    const { role } = currentUser || {}

    const { permissions: { action: { linkage: linkageRole, manualCreditScoring } } } = productForm.config;


    // useEffect(() => {
    //     if (linkage) {
    //     }
    // }, [linkage]);

    const runRuleEngine = async (entity: any) => {
        dispatch(resetLinkageData())
        try {
            const { data } = await http.post(`/entity/ruleEngine/${entity.id}/run`, {}, {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
                },
            });
            if (data && data[0] && data?.length) {
                dispatch(setEntity(entity))
                setLinkage(entity);
                return;
            }
            setMessage("Please Fill Complete Details for Linkage!");
            openSnackBar()

        } catch (error) {

            console.error("An error occurred while running the rule engine:", error)
            // setMessage("An error occurred while running the rule engine")
            openSnackBar()
        }

    }

    const saveLinkageData = async (entityId: number, linkageData: any) => {
        setLoading(true)
        const { status } = await http.post(`/entity/linkage`, {
            linkageData,
            entityId: entityId
        })

        if (status === 201) {
            setLoading(false)
            setMessage('Saved Data')
            openSnackBar()
        }
    }
    // console.log("saveLinkageData : ",saveLinkageData)

    const handleSetLinkage = (row: object) => {
        setLinkage(row);
    }
    // console.log("linkage now : ",linkage)

    if (tableColumns && tableRows) {
        tableColumns = [...tableColumns, {
            button: false,
            grow: 12,
            cell: (row: any) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        row.id &&
                        showViewButton &&
                        !edit &&
                        <button
                            style={{ marginTop: '1rem', maxWidth: '10rem' }}
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            onClick={(e) => {
                                dispatch(setEntity(null))
                                dispatch(resetLinkageData())
                                navigate(`${navigateUrl}/${row.id}`)
                            }}
                        >
                            <i className="ni ni-curved-next text-lg text-dark opacity-10 align-center" aria-hidden="true"></i>
                            {/* View */}
                        </button>
                    }
                    {
                        row.id &&
                        (
                            row.transitionstatus &&
                            !["initiated", "rejected"].includes(row.transitionstatus)
                        ) &&
                        showLinkageButton &&
                        !edit &&
                        linkageRole.view.includes(role) &&
                        <button
                            style={{ marginTop: '1rem', marginLeft: '1rem' }}
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            onClick={() => {
                                handleSetLinkage(row)
                                runRuleEngine(row)
                            }}
                        >
                            <i className="ni ni-sound-wave text-lg text-dark opacity-10 align-center" aria-hidden="true"></i>
                            {/* Linkage */}
                        </button>
                    }

                    {

                        row.id &&
                        edit &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button
                                style={{ marginTop: '1rem', width: '6rem' }}
                                type="button"
                                className="btn btn-sm bg-gradient-danger"
                                onClick={() => {

                                    localStorage.setItem('tableItem', JSON.stringify(row))
                                    setShowForm(!showForm);
                                    setSegmentMetaIdToEdit(row.id)
                                    onClickEdit(row.id)
                                }}
                            >
                                Edit
                            </button>
                            {
                                tab && (tab === 'Members') &&
                                <button
                                    className="btn bg-gradient-secondary"
                                    style={{ marginTop: '1rem', marginLeft: '1rem', width: '10rem' }}
                                    // onClick={() => displayMemberKycs()} //define a function to display member details
                                >
                                    View member details
                                </button>
                            }

                            {
                                tab && (tab === 'Members') &&
                                <button
                                    className="btn bg-gradient-secondary"
                                    style={{ marginTop: '1rem', marginLeft: '1rem', width: '10rem' }}
                                    onClick={() => creditReportHandler(row.id)}
                                >
                                    Pull Credit Report
                                </button>
                            }

                        </div>
                    }
                </div>
            )
        }]
    }


    const customStyles = {
        rows: {
            style: {
                minHeight: '84px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontWeight: '600'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                margin: '.5rem'
            },
        },
    };


    return (
        <>
            {
                showButton &&
                showViewButton &&
                <button
                    style={{ float: 'right', margin: '1rem' }}
                    className="btn btn-sm bg-gradient-secondary align-middle text-center text-sm p-2"
                    onClick={() => {
                        localStorage.removeItem('tableItem')
                        setSegmentMetaIdToEdit(null)
                        setShowForm(!showForm)
                        setMember(null)
                    }}
                >
                    <FontAwesomeIcon className='px-1' icon={faAdd} />
                    Add
                </button>
            }
            {
                <div style={{ marginTop: '1rem' }}>
                    <DataTable
                        columns={tableColumns?.length ? tableColumns : []}
                        data={tableRows?.length ? tableRows : []}
                        pagination
                        highlightOnHover
                        defaultSortFieldId={1}
                        fixedHeader
                        customStyles={customStyles}
                        {...props}
                    />
                </div>
            }

            <Modal show={linkage}>
                {
                    linkage &&
                    <>
                        <div style={{ width: 'calc(100vw - 100px)', marginBottom: '1rem', padding: '10px', border: '1px solid #d0d9d2', background: 'white', borderRadius: '5px' }}>
                            <FontAwesomeIcon style={{ paddingRight: '1rem', float: 'right', cursor: 'pointer' }} icon={faClose} onClick={() => setLinkage(undefined)} />
                            <Linkages user={currentUser} {...{ currentUser, manualCreditScoring }} entity={linkage} linkage={linkage['entitytype']?.toLowerCase() === 'fresh'} repeatLinkage={linkage['entitytype'] === 'mature'} onSave={saveLinkageData} />
                        </div>
                    </>
                }
            </Modal>

            <Modal show={showForm}>
                <div style={formStyle}>
                    <AutoFormComp
                        {...props}
                        edit={edit}
                        segmentMetaIdToEdit={segmentMetaIdToEdit}
                        setShowForm={setShowForm}
                        showForm={showForm}
                        title={`Add ${props.config?.formBuilderConfig.title}`}
                    />
                    {/* {
                        // (props.config?.bankDetailsSegment || props.config?.addressSegment) &&
                        console.log("props from Table : ", props)
                    }  */}
                    {

                        // (props.config?.bankDetailsSegment || props.config?.addressSegment) &&
                        <>
                            <AddressDetails {...props} edit={edit} />
                            <BankDetails {...props} edit={edit} />
                        </>
                    }
                </div>
            </Modal>

            <Snackbar isActive={isActive} message={message} />
        </>
    )
}

export default Table;
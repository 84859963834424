import { useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from './Tabs'
import http from '../utils/http';
import ErrorBoundary from './ErrorBoundaries';
import Segment from './Segment';
import Snackbar from './Snackbar';
import { useParams } from "react-router-dom";
import { useSnackbar } from '../hooks/useSnackbar'
import Member from '../types/Member';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import { updateSegment } from '../features/segment/segmentSlice';
import './segment.css'
import { useQuery } from '@tanstack/react-query'
import { productType } from '../utils';
import { currentTab } from '../features/tab/tabSlice';

type SegmentsProps = {
  edit?: boolean,
  segmentMetaData?: any,
  productForm?: any,
  disabled?: boolean,
  setLoading: Function
}

const Segments = ({ edit = false, segmentMetaData = [], productForm, disabled = false, setLoading }: SegmentsProps) => {
  const localMember = localStorage.getItem('tableItem');
  const [disableTab, setDisabledTab] = useState(true)
  const [memberIdToEdit, setMemberIdToEdit] = useState<Member>()
  // const [currentMember, setCurrentMember] = useState(localMember ? JSON.parse(localMember) : null)
  const [currentMember, setCurrentMember] = useState({})
  const [message, setMessage] = useState('')
  const { entityId }: any = useParams() || {}
  const localEntity = localStorage.getItem('currentEntity');
  const currentEntity = localEntity && JSON.parse(localEntity) || 1;
  const { isActive, openSnackBar } = useSnackbar();
  const { segment: { segments, currentSegment }, entity: { entity } , tab:{tab} ,sidebar :{activeMenu,activeSubmenu} } = useSelector((state: any) => {
    return state
  })

  const activeProduct: any = localStorage.getItem('activeMenu');


  const dispatch = useDispatch<AppDispatch>();

  const { entityMeta, entityMultiMetas, ...rest } = entity || {}

  const submitHandler = (formValue: any, segmentMetas: any[], config: any, segmentMetaIdToEdit: any) => {
    // console.log(config, 'config.bankValidation')
    // let isMultiMeta = false
    // let memberDetails: any = { isdCode: '91' };
    // const mapForVariabls: Record<string, any> = {
    //   emailId: 'email',
    //   mobileNo: 'mobile',
    //   memberName: 'name'
    // }
    // const formData: any[] = []
    // let multiData;

    // if (!edit) {
    //   const res = segmentMetas.reduce((acc: any, curr: any) => {
    //     const valueOfField = formValue[curr.metaField.name]
    //     const bankDetailsValidation = config.bankValidation ?
    //       {
    //         fieldName: curr.metaField.name,
    //         entityId: config.type === 'entity' && currentEntity.id,
    //         bankDetails: true
    //       } :
    //       {
    //         config: {}
    //       }
    //     const commonProperties = {
    //       ...bankDetailsValidation,
    //       segmentMeta: curr.id,
    //       config: {}
    //     }
    //     if (config.segmentConfig.multiple && valueOfField) {
    //       isMultiMeta = true
    //       acc = [
    //         ...acc,
    //         {
    //           ...commonProperties,
    //           value: valueOfField?.toString(),
    //         }
    //       ]
    //     }
    //     else if (config.type !== 'members') {
    //       formData.push({
    //         ...commonProperties,
    //         value: valueOfField?.toString(),
    //         segmentMetaConfig: curr.config[curr.metaField.name].kycRequired
    //       })
    //     }
    //     else if (config.type === 'members') {
    //       if (curr.config[curr.metaField.name].title === 'image') {
    //         formData.push({
    //           ...commonProperties,
    //           value: localStorage.getItem(curr.metaField.name)
    //         })
    //       }
    //       else formData.push({
    //         ...commonProperties,
    //         value: valueOfField?.toString(),
    //         segmentMetaConfig: curr.config[curr.metaField.name].kycRequired,
    //         fieldName: curr.metaField.name
    //       })
    //       memberDetails = {
    //         mobile: formValue['mobileNo'].toString(),
    //         email: formValue['emailId'],
    //         isdCode: '91',
    //         name: formValue['memberName']
    //       }
    //     }
    //     return acc
    //   }, [])
    //   multiData = [{ type: config.multiMetaType, data: [...res] }]
    // } else {
    //   if (config.segmentConfig.multiple && config.type !== 'members') {
    //     const entityMultiMeta = entityMultiMetas.find((e: any) => e.id === segmentMetaIdToEdit)
    //     if (entityMultiMeta) {
    //       const res = entityMultiMeta.segmentMetaDatum.reduce((acc: any, curr: any) => {
    //         const bankDetailsValidation = config.bankValidation ?
    //           {
    //             fieldName: curr.segmentMeta.metaField.name,
    //             entityId: config.type === 'entity' && currentEntity.id,
    //             bankDetails: true
    //           } :
    //           {
    //             config: {}
    //           }
    //         const commonProperties = {
    //           ...bankDetailsValidation,
    //           segmentMetaData: curr.id,
    //           config: {}
    //         }
    //         if (config.segmentConfig.multiple && formValue[curr.segmentMeta.metaField.name] && curr.segmentMetaData === acc.id) {
    //           isMultiMeta = true
    //           acc = [...acc, { value: formValue[curr.segmentMeta.metaField.name].toString(), ...commonProperties }]
    //         }
    //         return acc;
    //       }, [])
    //       multiData = [{ multiMeta: segmentMetaIdToEdit, data: [...res] }]
    //     }
    //     else {
    //       const res = segmentMetas.reduce((acc: any, curr: any) => {
    //         if (config.segmentConfig.multiple) {
    //           isMultiMeta = true
    //           acc = [
    //             ...acc,
    //             {
    //               value: formValue[curr.metaField.name]?.toString(),
    //               segmentMeta: curr.id,
    //               config: {}
    //             }
    //           ]
    //         }
    //         return acc
    //       }, [])
    //       multiData = [{ type: config.multiMetaType, data: [...res] }]
    //     }
    //   } else {
    //     if (!config.segmentConfig.multiple) {

    //       segmentMetas.reduce((acc: any, curr: any) => {
    //         if (['mobileNo', 'emailId', 'memberName'].includes(curr.metaField.name) && config.type === 'members') {
    //           const val = formValue[curr.metaField.name]
    //           if (val) {
    //             memberDetails = {
    //               ...memberDetails,
    //               [mapForVariabls[curr.metaField.name]]: val.toString()
    //             }
    //           }
    //           return acc;
    //         }

    //         const existsInSegmentMetaData = segmentMetaData.find((segmentMetas: any) => segmentMetas?.segmentMeta?.metaField?.id === curr.metaField.id);

    //         if (existsInSegmentMetaData) {
    //           // console.log(curr.config[curr.metaField.name]?.kycRequired,'existsInSegmentMetaData')
    //           acc = [
    //             ...acc,
    //             {
    //               value: formValue[curr.metaField.name] || existsInSegmentMetaData.value,
    //               segmentMetaData: existsInSegmentMetaData.id,
    //               // segmentMetaConfig: curr.config[curr.metaField.name]?.kycRequired,
    //               config: {}
    //             }
    //           ]
    //         } else if (formValue[curr.metaField.name]) {
    //           acc = [
    //             ...acc,
    //             {
    //               value: formValue[curr.metaField.name],
    //               segmentMeta: curr.id,
    //               config: {},
    //               segmentMetaConfig: curr.config,
    //               metaField: curr.metaField
    //             }
    //           ]
    //         }
    //         return acc;
    //       }, []).forEach((item: any) => {
    //         if (item.segmentMetaConfig && item.segmentMetaConfig[item.metaField.name].title === 'image') {
    //           const commonProperties = { segmentMeta: item.segmentMeta, config: {} }
    //           formData.push({
    //             ...commonProperties,
    //             value: localStorage.getItem(item.metaField.name),
    //           })
    //         } else {
    //           const { segmentMeta, segmentMetaData, value, segmentMetaConfig } = item;
    //           // console.log(item,'item')
    //           if (segmentMeta) {
    //             console.log('12')
    //             const segmentMetaConfigure = segmentMetaConfig && segmentMetaConfig[item.metaField.name] ?
    //               segmentMetaConfig[item.metaField.name] :
    //               {};
    //             formData.push({
    //               segmentMeta,
    //               value,
    //               segmentMetaConfig: segmentMetaConfigure,
    //               fieldName: item.metaField.name
    //             })
    //           } else {
    //             try {
    //               formData.push({
    //                 segmentMetaData,
    //                 // segmentMetaConfig: segmentMetaConfig && segmentMetaConfig,
    //                 value
    //               })

    //             } catch (error) {
    //               console.log('error is',error)
    //             }
    //           }
    //         }
    //       })
    //     } else {
    //       isMultiMeta = true
    //       const entityMultiMeta = memberIdToEdit && memberIdToEdit.memberMultiMetas && memberIdToEdit.memberMultiMetas.find((e: any) => e.id === segmentMetaIdToEdit)
    //       if (entityMultiMeta) {
    //         const res = entityMultiMeta.segmentMetaDatum.reduce((acc: any, curr: any) => {
    //           if (config.segmentConfig.multiple && formValue[curr.segmentMeta.metaField.name] && curr.segmentMetaData === acc.id) {
    //             const bankDetailsValidation = config.bankValidation ?
    //               {
    //                 fieldName: curr.segmentMeta.metaField.name,
    //                 member: config.type === 'members' && memberIdToEdit?.id,
    //                 bankDetails: true
    //               } :
    //               {
    //                 config: {}
    //               }
    //             const commonProperties = {
    //               ...bankDetailsValidation,
    //               segmentMetaData: curr.id,
    //               config: {}
    //             }
    //             acc = [...acc, { value: formValue[curr.segmentMeta.metaField.name].toString(), ...commonProperties }]
    //           }
    //           return acc;
    //         }, [])
    //         multiData = [{ multiMeta: segmentMetaIdToEdit, data: [...res] }]
    //       } else {
    //         const res = segmentMetas.reduce((acc: any, curr: any) => {
    //           const bankDetailsValidation = config.bankValidation ?
    //             {
    //               fieldName: curr.metaField.name,
    //               member: config.type === 'members' && memberIdToEdit?.id,
    //               bankDetails: true
    //             } :
    //             {
    //               config: {}
    //             }
    //           const commonProperties = {
    //             ...bankDetailsValidation,
    //             segmentMeta: curr.id,
    //             config: {}
    //           }
    //           return [
    //             ...acc,
    //             {
    //               value: formValue[curr.metaField.name]?.toString(),
    //               ...commonProperties
    //             }
    //           ]
    //         }, [])
    //         multiData = [{ type: config.multiMetaType, data: [...res] }]
    //       }
    //     }
    //   }
    // }

    // try {
    //   const entityRes = {
    //     ...rest,
    //     config: {},
    //     metaData: !isMultiMeta ? formData : [],
    //     multiMetaData: isMultiMeta ? multiData : []
    //   }

    //   console.log(entityRes,'entityRes')

    //   setLoading(true);

    //   if (config.type !== 'members') {
    //     if (!edit && config.initialTab) {
    //       http.post(`${process.env.REACT_APP_API_BASE_URL}/entity`,
    //         { ...entityRes, entityNo: '23', config: {} }
    //       )
    //         .then(({ data }) => {
    //           localStorage.setItem('currentEntity', JSON.stringify(data))
    //           setDisabledTab(!disableTab)
    //           setMessage('Saved Successfully')
    //           openSnackBar()
    //           setLoading(false);
    //         })
    //         .catch(err => {
    //           console.log("error is", err)
    //           setLoading(false);
    //         })
    //     }
    //     else {
    //       http.patch(`${process.env.REACT_APP_API_BASE_URL}/entity/${entityId || currentEntity.id}`,
    //         entityRes
    //       )
    //         .then(() => {
    //           setMessage('Saved Successfully')
    //           openSnackBar()
    //           setLoading(false);
    //         })
    //         .catch(({ message }) => {
    //           setMessage(message)
    //           openSnackBar()
    //           setLoading(false);
    //         })
    //     }
    //   }
    //   else {
    //     if (!currentMember) {
    //       http.post(`${process.env.REACT_APP_API_BASE_URL}${config.url}`, { ...entityRes, ...memberDetails, entity: currentEntity.id })
    //         .then(({ data }) => {
    //           localStorage.setItem('tableItem', JSON.stringify(data.member));
    //           localStorage.setItem('verificationId', JSON.stringify(data.showOtpModal.id))
    //           setCurrentMember(data.member)
    //           setMessage('Saved Successfully')
    //           openSnackBar()
    //           setLoading(false);
    //         })
    //         .catch((e) => {
    //           console.log(e, 'error')
    //           setLoading(false);
    //         })
    //     } else {
    //       http.patch(`${config.url}/${(memberIdToEdit && memberIdToEdit.id) || currentMember.id}`, entityRes)
    //         .then(() => {
    //           setMessage('Saved Successfully')
    //           openSnackBar()
    //           setLoading(false);
    //         })
    //         .catch(({ message }) => {
    //           setMessage(message)
    //           openSnackBar()
    //           setLoading(false);
    //         })
    //     }
    //   }

    // }
    // catch (e) {
    //   console.log(e, 'error while saving the data')
    //   setLoading(false);
    // }


    let formValuesWithConfig: any = { kycRequired: [] }
    // let formValuesWithConfig: any = {}

    segmentMetas.map((p: any) => {

      if (p.config[p.metaField.name]?.kycRequired) {
        formValuesWithConfig.kycRequired.push({ nameOfKyc: p.config[p.metaField.name]?.kycRequired?.kyc, value: formValue[p.metaField.name] })
      }
      formValuesWithConfig = { ...formValuesWithConfig,[p.metaField.name]: formValue[p.metaField.name] }
      // if(p.config[p.metaField.name]?.title === "image"){
      //   formValuesWithConfig = { ...formValuesWithConfig, [p.metaField.name]: localStorage.getItem(p.metaField.name) }
      // }
    })

    // const currentData = rest.id && rest.id || rest.data.find((s: any) => s.id === currentEntity)
    // console.log("currentData from Segments:  ", currentData,currentEntity)

    let payload = {}

    if (config.tabType === "address" ) {
      const currentAddress = segmentMetaIdToEdit ? rest.address.find((s: any) => {
        if (s.id === segmentMetaIdToEdit) {
          return s
        }
      }) : null
      payload = {
        address :[{ ...currentAddress, ...formValue }]
      }
    }
    
    if(config.tabType === "bank"){
      const currentBankDetails = segmentMetaIdToEdit ? rest.bank.find((s: any) => {
        if (s.id === segmentMetaIdToEdit) {
          return s
        }
      }) : null
      payload={
        bank:[{...currentBankDetails,...formValue}]
      }
    }




    // if (config.tabType === "bank") {
    //   payload = {
    //     ...currentData,
    //     bank: [...currentData.bank, formValue]
    //   }
    // }





    try {
      if (config.subType && config.subType !== 'members') {
        if (!edit && config.initialTab) {
          http.post(`${process.env.REACT_APP_API_BASE_URL}/entity/shgNewFlow`,
            { ...formValuesWithConfig, product: (productType as any)[activeProduct] }
          )
            .then(({ data }) => {
              localStorage.setItem('currentEntity', JSON.stringify(data))
              setDisabledTab(!disableTab)
              setMessage('Saved Successfully')
              openSnackBar()
              setLoading(false);
            })
            .catch(err => {
              console.log("error is", err)
              // setLoading(false);
            })          
        }
        else {
          // console.log("Finance Test : ",{...formValuesWithConfig},{...payload},{...formValuesWithConfig,...payload})
          http.patch(`${process.env.REACT_APP_API_BASE_URL}/entity/updateShgNewFlow/${entityId || currentEntity.id}`,
            { ...formValuesWithConfig, ...payload }
          )
            .then(() => {
              setMessage('Saved Successfully')
              openSnackBar()
              setLoading(false);
            })
            .catch(({ message }) => {
              setMessage(message)
              openSnackBar()
              setLoading(false);
            })
        }
      }
      if (config.subType && config.subType === "members") {
        // if (!currentMember) {
        http.post(`${process.env.REACT_APP_API_BASE_URL}/members/memberNewFlow`, { ...formValuesWithConfig, entity: 1 || currentEntity?.id })
          .then(({ data }) => {
            localStorage.setItem('tableItem', JSON.stringify(data));
            // localStorage.setItem('verificationId', JSON.stringify(data.showOtpModal.id))
            setCurrentMember(data)
            setMessage('Saved Successfully')
            openSnackBar()
            setLoading(false);
          })
          .catch((e) => {
            console.log(e, 'error')
            setLoading(false);
          })
      }

      


      // }
      // else {
      //   http.patch(`${config.url}/${(memberIdToEdit && memberIdToEdit.id) || currentMember.id}`, entityRes)
      //     .then(() => {
      //       setMessage('Saved Successfully')
      //       openSnackBar()
      //       setLoading(false);
      //     })
      //     .catch(({ message }) => {
      //       setMessage(message)
      //       openSnackBar()
      //       setLoading(false);
      //     })
      // }
    } catch (error) {

    }
  }

  const segmentsToRender = segments.filter((segment: any) => segment.config?.type === activeProduct?.toLowerCase())

  // console.log("segment to render : ",segmentsToRender)
  return (
    <ErrorBoundary>
      
      {
        segmentsToRender && <>
          <div className="row flex-row flex-nowrap">
            <ul className="nav-pills nav-fill p-1 relative rounded-xl" style={{ display: '-webkit-box', overflow: 'auto' }} role="tablist" >
              {segmentsToRender?.map((segment: any, index: number) => {
                return (
                  <>
                    {

                      segment.config.showTab && activeProduct?.toLowerCase() === segment.config.type &&
                      <Tabs
                        index={index}
                        key={index}
                        segment={segment}
                        setShowIndex={
                          // (ind: number) => setShowIndex(ind)
                          (ind: number) => {
                            localStorage.setItem("tab", segment.name)
                            dispatch(currentTab(segment.name))
                            dispatch(updateSegment(ind))
                          }
                        }
                        showIndex={currentSegment}
                        icon={segment.config.icon}
                      />
                    }
                  </>
                )
              })}
            </ul>
          </div>
          {
            tab && (tab==='SHG Details') && (activeSubmenu && activeSubmenu === 'View') &&
            <>
            <button style={{ border: 'none', marginLeft: '1rem', cursor: 'pointer' }}
                        className={entity?.isPanVerified === true ?'btn btn-sm bg-gradient-success' : 'btn btn-sm bg-gradient-danger'}>
                        <>
                            {((entity?.isPanVerified === true) ? 'Pan Verified' : 'Pan Not Verified')}
                            {/* <FontAwesomeIcon style={{ paddingLeft: '1rem' }}  /> */}
                        </>
                    </button>

                    <button style={{ border: 'none', marginLeft: '1rem', cursor: 'pointer' }}
                        className={entity?.isUdhyamVerified === true ?'btn btn-sm bg-gradient-success':'btn btn-sm bg-gradient-danger'}>
                        <>
                            {((entity?.isUdhyamVerified === true) ? 'Udhyam Verified' : 'Udhyam Not Verified')}
                            {/* <FontAwesomeIcon style={{ paddingLeft: '1rem' }}  /> */}
                        </>
                    </button>
            </>
          }
          {
            <>
              {
                segmentsToRender?.map((segment: any, index: number) => {
                  return (currentSegment === segment.sequence) &&
                    <Segment
                      key={index}
                      {
                      ...{
                        config: segment.config,
                        id: segment.id,
                        disabled,
                        index,
                        segmentMetaData,
                        edit,
                        setMemberIdToEdit,
                        setLoading
                      }
                      }
                      currentEntity={currentEntity || entity}
                      onSubmit={submitHandler}
                      entities={entity}
                      productForm={productForm}
                    />
                })
              }
            </>
          }
        </>
      }
      <Snackbar isActive={isActive} message={message} />
    </ErrorBoundary>
  )
}

Segments.propTypes = {
  segments: PropTypes.any,
  edit: PropTypes.bool,
  segmentMetaData: PropTypes.array,
  disabled: PropTypes.bool,
  entity: PropTypes.object,
  setLoading: PropTypes.func,
  productForm: PropTypes.any
}

export default Segments
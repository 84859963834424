import { faAdd, faSubtract, faEye, faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Segment from "./Segment";
import Modal from './Modal';



// type AccordionProps = {
//     id: number | string,
//     title: string,
//     content: any,
//     entity: {
//         id: any,
//         verification: any
//     }
//     ,
//     user: {
//         role: any
//     },
//     memberDetails: {
//         name: string,
//         config: any,
//         id: any
//     },
//     productForm: {
//         config: any
//     },
//     displayContent: boolean
// }

type AccoridionProps1 = {
    id: number | string,
    // title: string,
    content: {
        title: any,
        values: any
    },
    displayContent: boolean,
    user: {
        role: any
    }
    entity: any,
    productForm: any
}

type memberKycItems = {
    memberId: number
    memberDocuments: any[]
}

type kycDetails = {
    entityKYC?: any[]
    panData?: any
    udhyamData?: any
    memberKYC?: memberKycItems[]
}

type seperateKyc = {
    kycName?: string,
    kycDetails?: object | any[]
}

function Accordion({ content, displayContent = false, user, entity, id, productForm }: AccoridionProps1): JSX.Element {
    const [docs, setDocs] = useState<any[] | null>([])
    console.log("The docs : ", docs)
    const [isActive, setIsActive] = useState(false);
    const [memberId, setMemberId] = useState<number | null>();

    useEffect(() => {
        setIsActive(displayContent);
    }, [displayContent]);


    async function handleMemberId(id: any) {
        setMemberId(id);
    }


    const includeKyc = ["SHG Details"]
    const includeIterateKyc = ["Members"]


    const [ekycModal, setEkycModal] = useState(false)

    let kycDetails: kycDetails = {}
    let seperateKycDetails: seperateKyc[] = []

    if (entity?.entityPan || entity?.udhyamRegistration) {

        if (entity?.isPanVerified === true) {
            let sepKyc: seperateKyc = {}
            sepKyc["kycName"] = "shgPan",
                sepKyc["kycDetails"] = entity?.panData
            seperateKycDetails.push(sepKyc)
        }
        if (entity?.isUdhyamVerified === true) {
            let sepKyc: seperateKyc = {}
            sepKyc["kycName"] = "udhyam",
                sepKyc["kycDetails"] = entity?.udhyamData
            seperateKycDetails.push(sepKyc)
        }

        kycDetails["entityKYC"] = seperateKycDetails
        // kycDetails["memberKYC"] = []

        // entity?.members?.map((member: any) => {
        //     const items = ["isPanVerified", "isPassportVerified", "isVoterIdVerified", "isAadhaarVerified", "isDrivingLicenseVerified"]
        //     let memberId = member?.id
        //     let verifiedDocs: any[] = []
        //     items.map((item: any) => { 
        //         if (member[item]) {
        //             verifiedDocs.push(item.replace(/^is(.+)Verified$/, "$1"))
        //         }
        //     })
        //     let memberDetails = { memberId, memberDocuments: verifiedDocs }
        //     kycDetails["memberKYC"]?.push(memberDetails)
        // })
    }

    console.log("The kycDetails : ", kycDetails)


    useEffect(() => {
        const fetchMemberData = async () => {
            const member: any = kycDetails?.memberKYC?.find((item: any) => item.memberId === memberId);
            if (member && member.memberDocuments) {
                setDocs(member.memberDocuments);
            }
            else {
                setMemberId(null)
                setDocs(null)
            }
        };

        if (memberId !== null) {
            fetchMemberData();
        }
    }, [memberId]);



    const { permissions: { action: { ekycDetails } } } = productForm.config || {}

    let finalArr: any = [];
    const getObject: any = (theObject: any, name: string) => {
        console.log("object kya hei", theObject, name)
        let result = null;
        if (theObject instanceof Array) {
            for (let i = 0; i < theObject.length; i++) {
                result = getObject(theObject[i]);
                if (result) {
                    break;
                }
            }
        } else {
            for (const prop in theObject) {
                let temp = [];
                temp[0] = prop;
                temp[1] = theObject[prop];
                typeof temp[1] === 'string' ? finalArr.push(temp) : null;
                console.log(finalArr, result, 'finalArr')
                temp = [];
                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    result = getObject(theObject[prop]);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    }


    return (
        <div className="row">
            <div className="accordion-item">
                <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="col-md-12 mx-auto">
                        <div className="accordion" id="accordionRental">
                            <div className="accordion-item mb-3">
                                <h5 className="accordion-header" id="headingOne">
                                    <button className="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        {content.title}
                                        <div>
                                            <FontAwesomeIcon className="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" icon={isActive ? faSubtract : faAdd} />
                                        </div>
                                    </button>
                                </h5>
                            </div>
                            {
                                isActive &&
                                <div id="collapseOne" className="accordion-collapse" aria-labelledby="headingOne" data-bs-parent="#accordionRental">
                                    {
                                        //Ekyc details - To display pan and aadhaar data

                                        ekycDetails.view.includes(user?.role) && includeKyc.includes(content?.title) && ((entity?.isPanVerified === true) || (entity?.isUdhyamVerified === true)) &&
                                        <button style={{ border: 'none', width: '15rem', height: '3rem', cursor: 'pointer' }}
                                            className='btn bg-gradient-default' onClick={() => {
                                                setEkycModal(!ekycModal);
                                                // console.log("Pan data received is {} ", ); 
                                                // kycDetails?.entityKYC && setDocs([kycDetails])
                                            }}>
                                            Ekycs Details
                                            <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faEye} />
                                        </button>
                                    }
                                    {

                                        <>
                                            {

                                                // memberDetails.name === title ?

                                                //     <div className="card card-frame">
                                                //         <Segment disabled={false} edit={false} {...{ config: memberDetails.config, id: memberDetails.id, segmentMetaData: [] }} productForm={productForm} currentEntity={entity} entities={entity} showViewButton={false} />
                                                //     </div> :
                                                //     <>
                                                //         {
                                                //             content.map((c, i) => (
                                                //                 <>
                                                //                     {
                                                //                         // c.multiMetaId ?
                                                //                         //     (
                                                //                         //         <>
                                                //                         //             <h6> {`${i + 1})`} </h6>
                                                //                         //             {
                                                //                         //                 c.values.map((c1: any, i1: any) => (
                                                //                         //                     <>
                                                //                         //                         <div className="accordion-body text-sm opacity-8" style={{ padding: 0 }} key={`${i1}`}>
                                                //                         //                             <label>{c1.key}</label>
                                                //                         //                             :
                                                //                         //                             <label style={{ color: 'red' }}>
                                                //                         //                                 <strong>{c1.value}</strong>
                                                //                         //                             </label>
                                                //                         //                             <br />
                                                //                         //                         </div>
                                                //                         //                     </>
                                                //                         //                 ))
                                                //                         //             }
                                                //                         //         </>
                                                //                         //     ) :
                                                //                         //     <div className="accordion-body text-sm opacity-8" style={{ padding: 0 }} key={`${i}`}>
                                                //                         //         <label>{c.key}</label>
                                                //                         //         :
                                                //                         //         <label style={{ color: 'red' }}>
                                                //                         //             <strong>{c.value}</strong>
                                                //                         //         </label>
                                                //                         //         <br />
                                                //                         //     </div>
                                                //                         <div>
                                                //                             {/* {c.value} */}
                                                //                             hi
                                                //                         </div>
                                                //                     }
                                                //                 </>
                                                //             ))
                                                //         }
                                                //     </>
                                                <div>

                                                    {
                                                        !Array.isArray(content?.values) ?
                                                            <>
                                                                {Object.keys(content?.values).map((key: any) => (

                                                                    !Array.isArray(content?.values[key]) ?
                                                                        (
                                                                            <>
                                                                                <label>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</label>
                                                                                :
                                                                                <label style={{ color: 'red' }}>
                                                                                    <strong>{content?.values[key]}</strong>
                                                                                </label>
                                                                                <br />
                                                                            </>
                                                                        )
                                                                        :
                                                                        (
                                                                            <>
                                                                                <h6>{key.toUpperCase()}</h6>
                                                                                {
                                                                                    content?.values[key].map((subKey: any) => {

                                                                                        return (
                                                                                            Object.entries(subKey).map((value: any) => (
                                                                                                <>
                                                                                                    <label>{value[0].replace(/([A-Z])/g, ' $1').toUpperCase()}</label>
                                                                                                    :
                                                                                                    <label style={{ color: 'red' }}><strong>{value[1]}</strong></label>
                                                                                                    <br />
                                                                                                </>
                                                                                            ))
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        )
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (content?.values || []).map((p: any, ind: number) => (
                                                                        <div key={ind}>
                                                                            <h6>{ind + 1})</h6>
                                                                            {Object.entries(p).map((s: any) => (
                                                                                <div key={s[0]}>
                                                                                    <label>{s[0].replace(/([A-Z])/g, ' $1').toUpperCase()}</label>:&nbsp;
                                                                                    <label style={{ color: 'red' }}>
                                                                                        <strong>{s[1]}</strong>
                                                                                    </label>
                                                                                    <br />
                                                                                </div>
                                                                            ))}
                                                                            {/* {
                                                                                includeIterateKyc.includes(content?.title) &&
                                                                                <button style={{ border: 'none', width: '15rem', height: '3rem', cursor: 'pointer' }}
                                                                                    className='btn bg-gradient-default' onClick={() => {
                                                                                        setEkycModal(!ekycModal);
                                                                                        kycDetails?.memberKYC && handleMemberId(p.id)

                                                                                    }}>
                                                                                    Ekycs Details
                                                                                    <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faEye} />
                                                                                </button>

                                                                            } 
                                                                            
                                                                            removed button for memberkyc details*/}
                                                                            <br />
                                                                        </div>
                                                                    ))
                                                                }

                                                            </>
                                                    }{

                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
            /* <Modal show={ekycModal} >
                <div className='card' style={{ width: '38rem', padding: '1rem' }}>
                    <div className="accordion-flush">
                        <FontAwesomeIcon style={{ paddingLeft: '2rem', float: 'right', cursor: 'pointer' }} icon={faClose} onClick={() => setEkycModal(!ekycModal)} />
                        <div className="container">
                            {kycItems.map((verificationItem: any) => {
                                getObject(verificationItem.kycDetails)
                                return (
                                    <>
                                        <p><b>{verificationItem.segmentMeta.metaField.displayName}</b></p>
                                        {finalArr.filter(p => !['code', 'message', 'request_id', 'path'].includes(p[0])).map(p => {
                                            return (
                                                <>
                                                    <label>{p[0].split('_').join(' ')}</label>
                                                    :
                                                    <label style={{ color: 'red', wordBreak: 'break-word' }}>{p[1]}</label>
                                                    <br />
                                                </>
                                            )
                                        })}
                                        <hr />
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Modal>*/}

            {
                <Modal show={ekycModal}>
                    <div className='card' style={{ width: '38rem', padding: '1rem' }}>
                        <div className="accordion-flush">
                            <FontAwesomeIcon style={{ paddingLeft: '2rem', float: 'right', cursor: 'pointer' }} icon={faClose} onClick={() => setEkycModal(!ekycModal)} />
                            {/* <div className="container">
                            </div> */}
                            {
                                content.title === "SHG Details" && (kycDetails?.entityKYC || []).map((kyc: any, ind: number) => {
                                    { finalArr=[] }
                                    return (
                                        <>
                                            <h6 key={ind}>{ind + 1})  {kyc.kycName.replace(/([A-Z])/g, ' $1').toUpperCase()}</h6>
                                            {getObject(kyc.kycDetails, kyc.kycName)}
                                            {finalArr.map((k: any) => {
                                                if (!(k[0] || k[1])) return null
                                                return (
                                                    <>
                                                        <label>{k[0].split('_').join(' ')}</label>
                                                        :
                                                        <label style={{ color: 'red', wordBreak: 'break-word' }}>{k[1]}</label>
                                                        <br />
                                                    </>
                                                )
                                            }
                                            )
                                            }
                                            <hr />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal>
            }
        </div >
    )
}

export default Accordion;
import { useEffect, useState } from "react";
import Table from "../components/Table";
import http from "../utils/http";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import { useParams } from 'react-router-dom';
import { getProduct } from "../features/product/productSlice";
import Loader from "../components/Loader";
import { useQuery, useQueryClient } from '@tanstack/react-query'

interface EntityColumn {
    [index: number]: {
        id?: any;
        title?: any;
        value?: any
    }
}

const EntityView = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const { productId } = useParams()
    const queryClient = useQueryClient()

    const [column, setColumn] = useState<EntityColumn>()

    const { product } = useSelector((state: any) => state.product)

    useEffect(() => {
        if (productId) {
            const prdId = parseInt(productId);
            if (!product) dispatch(getProduct(prdId))

        }
    }, [productId])

    const columns: { id: number, title: any, value: any }[] = []

    const fetchEntity = async () => {
        if (product && product?.config && product?.config?.endPoint) {
            const { data: { data: resData } } = await http.get(product.config.endPoint)
            return resData
        }
    }

    const { isLoading: isLoadingState, data: responseData } = useQuery({ queryKey: ['entityDetails', { type: 'fetchEntityDetails' }], queryFn: fetchEntity, enabled: !!queryClient.getQueryData(['entityDetails', { type: 'fetchEntityDetails' }]) || !!product })

    useEffect(() => {
        getTableData()
    }, [responseData])

    const getTableData = () => {
        responseData?.forEach((entity: any) => {
            Object.entries(entity).forEach((s: any) => {
                if (typeof s[1] !== 'object') {
                    columns.push({ id: entity.id, title: s[0], value: s[1] })
                    setColumn([...columns])

                }
                else if (s[0] === 'entityMeta') {
                    s[1].segmentMetaDatum.forEach((smd: any) => {
                        columns.push({
                            id: entity.id,
                            title: smd.segmentMeta.metaField.displayName,
                            value: smd.value?.toUpperCase()
                        })
                        setColumn([...columns])
                    })
                }
            });
        })
        // console.log("entity column:", columns)
    }

    return (
        <>
            {isLoadingState && <Loader />}
            {
                column &&
                <div className='container-fluid p-4'>
                    <Table displayConfig={product.config || {}} setLoading={setIsLoading} columns={column} productForm={product?.productForms[0]} showViewButton showLinkageButton />
                </div>

            }
        </>
    )
}

export default EntityView;
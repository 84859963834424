import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import segmentService from './segmentService';

const localSegments = localStorage.getItem('segments');

const segments: any[] = localSegments && JSON.parse(localSegments);

const initialState = {
    segments: segments || [],
    segment: null,
    currentSegment: 0
};

//get segments
export const getSegments = createAsyncThunk("segments/getAll", async () => {
    const segments = await segmentService.getAll();
    return segments.data.data;
})

export const getSegment = createAsyncThunk("segments/get", async (id:number, thunkApi) => {
    try {
        const segment = await segmentService.get(id);
        return segment.data;
    } catch (err) {
        console.log(err);
        return thunkApi.rejectWithValue(err)
    }
})


export const segmentSlice = createSlice({
    name: 'segments',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.products = []
            localStorage.removeItem('segments');
        },
        updateSegment: (state: any, action) => {
            state.currentSegment= action.payload
        }
    }
    ,
    extraReducers: (builder) => {
        builder
            // get segments
            .addCase(getSegments.pending, (state) => {
                state.segments = []
            })
            .addCase(getSegments.fulfilled, (state, action) => {
                state.segments = action.payload
                localStorage.setItem('segments', JSON.stringify(state.segments));
            })
            .addCase(getSegments.rejected, (state, action) => {
                state.segments = [];
            })

            // get segment
            .addCase(getSegment.pending, (state) => {
                state.segment = null
            })
            .addCase(getSegment.fulfilled, (state, action) => {
                state.segment = action.payload
            })
            .addCase(getSegment.rejected, (state, action) => {
                state.segment = null;
            })
    }
})

export const { reset, updateSegment } = segmentSlice.actions;

export default segmentSlice.reducer;
import { useState, useEffect } from 'react';
import AutoFormComp from "./AutoFormComp";

type AutoFormFieldArrayProps = {
    config: object,
    title: string,
    segmentMeta: any,
    onSubmit: Function,
    disabled: boolean,
    memberDetails: any,
    type: string,

    segmentMetaData: any[]

}

const AutoFormFieldArray = ({ config, title, segmentMeta, onSubmit, disabled, memberDetails, type }: AutoFormFieldArrayProps) => {
    const [initialForm, setInitialForm] = useState<any[]>([])


    useEffect(() => {
        setInitialForm(memberDetails ? [memberDetails] : [{ address: [], bank:[] }]);
    }, [memberDetails])

    const handleAdd = (event: any) => {
        event.preventDefault()
        setInitialForm(
            (initialForm: any[]) => {
                const updatedForm = initialForm?.map((a: any) => {
                    a[type] = [...a[type], {}]
                    return a
                })
                return updatedForm
            })
    }

    const handleDelete = (event: any,a_ind:number) => {
        event.preventDefault()
        setInitialForm(
            (initialForm: any[]) => {
                   
                const updatedForm = initialForm?.map((a: any) => {
                    a[type]?.splice(a_ind-1,1)
                    return a
                })
                return updatedForm
            }
        )
    }




    return (

        <>
            <p style={{ textAlign: 'center', marginTop: '3rem' }}>{title}</p>
            {initialForm?.map((a: any, ind: number) => {
                return (
                    <div
                        key={ind}
                        style={{
                            marginTop: '1rem',
                            border: '1px solid #d0d9d2',
                            background: 'white',
                            borderRadius: '5px',
                            margin: '1rem'
                        }}
                    >
                        {
                            a[type]?.map((i: any,a_ind:number) => {
                                return (
                                    <>
                                        <AutoFormComp
                                            key={ind}
                                            {...{
                                                config,
                                                title,
                                                segmentMeta,
                                                onSubmit,
                                                disabled
                                            }}
                                            segmentMetaIdToEdit={a.id}
                                            segmentMetaData={i}
                                            showIcon={false}
                                            edit={true}
                                        />
                                        <button
                                            onClick={(e) => handleDelete(e,a_ind)}
                                            style={{
                                                border: 'none',
                                                height: '3rem',
                                                marginTop: '1rem',
                                                marginLeft: '1rem'
                                            }}
                                            className="btn btn-sm bg-gradient-danger align-middle text-center text-sm"
                                        >
                                            Delete
                                        </button>
                                    </>
                                )
                            })
                        }
                        {

                        }
                    </div>
                )
            })}

            {

                <button
                    onClick={(e) => handleAdd(e)}
                    style={{
                        border: 'none',
                        width: '10rem',
                        height: '3rem',
                        margin: '1rem'
                    }}
                    className="btn btn-sm bg-gradient-outline-success align-middle text-center text-sm"
                >
                    Add
                </button>
            }
        </>
    );

}

export default AutoFormFieldArray
import Styles from './snackbar.module.css'

const Snackbar = ({ ...props }: any) => {

    const { isActive = false, message = 'Something went wrong' } = props;
    return (
        <div
            className={isActive
                ? [Styles.snackbar, Styles.fadeIn].join(" ")
                : [Styles.snackbar, Styles.fadeOut].join(' ')}
        >
            {message}
        </div>
    )
}

export default Snackbar;
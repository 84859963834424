import Todo from '../../types/Todo'
import http from '../../utils/http'

const getAll = async () => {
  const todos = await http.get('todos')
  return todos.data
}

const create = async (body: Todo) => {
  const todoCreaated = await http.post('todos', body)
  return todoCreaated.data
}

const todoService = {
  getAll,
  create
}

export default todoService

import './loader.css';

function Loader() {
    return (
        <div style={{ zIndex: 10000, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', background: '#00000055'  }}>
            <div>
                <div style={{ width: '50px', height: '50px' }} className="loader"></div>
            </div>
        </div>
    )
}

export default Loader
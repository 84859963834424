import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Todo from '../../types/Todo'
import todoService from '../test/todoService'

const localTodos = localStorage.getItem('todos')

const todos: Todo[] = localTodos && JSON.parse(localTodos)

const initialState = {
  todos: todos || []
}

// get todos
export const getTodos = createAsyncThunk('todos/get', async () => {
  const todos = await todoService.getAll()
  return todos
})

export const addTodo = createAsyncThunk('todos/add', async (todo: Todo, thunkApi) => {
  try {
    const createdTodo = await todoService.create(todo)
    return createdTodo
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue(err)
  }
})

export const todoSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    reset: (state: any) => {
      state.todos = []
      localStorage.removeItem('todos')
    }
  },
  extraReducers: (builder) => {
    builder
    // get todos
      .addCase(getTodos.pending, (state) => {
        state.todos = []
      })
      .addCase(getTodos.fulfilled, (state, action) => {
        state.todos = action.payload
        localStorage.setItem('todos', JSON.stringify(state.todos))
      })
      .addCase(getTodos.rejected, (state, action) => {
        state.todos = []
      })

    // add todos
      .addCase(addTodo.pending, (state) => {
      })
      .addCase(addTodo.fulfilled, (state, action) => {
        state.todos = [
          ...state.todos,
          action.payload
        ]
        localStorage.setItem('todos', JSON.stringify(state.todos))
      })
      .addCase(addTodo.rejected, (state, action) => {
        state.todos = []
      })
  }
})

export const { reset } = todoSlice.actions

export default todoSlice.reducer

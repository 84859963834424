import moment from "moment"
import { toSentenceCase } from "../utils"

const CreditReport = ({ creditReportData, showModal, setShowModal }: any) => {
    const { score, retailAccounts, userInfo } = creditReportData || {}

    const accountDetails = retailAccounts.map((account: any) => {
        return {
            Institution: account.institution,
            'Account Number': account.accountNumber,
            'Account Type': account.accountType,
            'Ownership Type': account.ownershipType,
            Balance: account.balance,
            'Past Due Amount': account.pastDueAmount,
            Open: account.open,
            'Sanction Amount': account.sanctionAmount,
            'Last Payment Date': moment(account.lastPaymentDate).format('DD-MM-YYYY'),
            'Date Reported': moment(account.dateReported).format('DD-MM-YYYY'),
            'Date Opened': moment(account.dateOpened).format('DD-MM-YYYY'),
            'Account Status': account.accountStatus,
            Source: account.source
        }
    })

    return (
        <div style={{ border: '1px solid #d0d9d2', background: 'white', borderRadius: '10px', padding: '3rem' }}>
            <h5>Credit Report Details</h5>
            <hr />
            <div className="grid-container">
                <p className="fw-bold">
                    Credit Score:
                </p>
                <p>
                    {score}
                </p>
            </div>
            <p className="fw-bold my-sm-3">Retail Accounts :</p>
            {accountDetails.map((a: any, ind: number) => {
                return (
                    <div key={ind} className="grid-container" style={{ border: '1px solid #d0d9d2', background: 'white', borderRadius: '10px', padding: '3rem' }}>
                        {Object.entries(a).map((b: any) => (
                            <>
                                <p className="grid-item fw-bold">{b[0]} :</p>
                                <p className="grid-item">{b[1] || 'NIL'}</p>
                            </>
                        ))}
                    </div>
                )
            })}
            <div className="my-sm-3">
                <p className="fw-bold">Credit Report Address :</p>
                {userInfo.addresses.map((address: any, index: number) => {
                    return (
                        <div key={index} className="grid-container" style={{ border: '1px solid #d0d9d2', background: 'white', borderRadius: '10px', padding: '3rem' }}>
                            {Object.entries(address).map((b: any) => {
                                return (
                                    <>
                                        {
                                            b[0] !== "state" &&
                                            <>
                                                <p className="grid-item fw-bold">{toSentenceCase(b[0])} :</p>
                                                <p className="grid-item">{b[1] || 'NIL'}</p>
                                            </>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="grid-container justify-content-center">
                <button className="btn btn-primary" onClick={() => setShowModal(!showModal) }>Close</button>
            </div>
        </div>

    )
}

export default CreditReport;
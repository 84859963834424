import { useState, useEffect } from 'react';
import { HTMLFieldProps, connectField } from 'uniforms';
import { useSnackbar } from '../hooks/useSnackbar';
import http from '../utils/http';
import Snackbar from './Snackbar';

export type ImageFieldProps = HTMLFieldProps<string, HTMLDivElement>;

function Image({ onChange, value, label, name }: any) {

  const [unsignedPath, setUnsignedPath] = useState(value);
  const [imageSrc, setImageSrc] = useState('https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png');

  useEffect(() => {
    if(unsignedPath || (value && value.includes('https://s3.ap-south-1.amazonaws.com'))){
      http.get(`entity/getSignedUrl?file=${unsignedPath || value}`)
      .then(response => setImageSrc(response.data))
      .catch(error => console.log('image signing error', error))
    }
  }, [value, unsignedPath])

  const [message, setMessage] = useState('')
  const { isActive, openSnackBar } = useSnackbar()

  const onChangeHandler = async (files: any) => {
    onChange(URL.createObjectURL(files))
    try {
      const formData = new FormData()
      formData.append('files', files)
      http.post('/members/upload', formData)
        .then(({data}) => {
          localStorage.setItem(name, data.Location)
          setUnsignedPath(data.Location);
          setMessage('Successfully Uploaded')
          openSnackBar()
        })
        .catch(({ message }) => {
          setMessage(message)
          openSnackBar()
        })
    }
    catch (e) {
      console.log('error is', e)
    }
  }

  return (

    <div>
      <label htmlFor="upload-photo">{label}</label>
      <div style={{ height: '200px', width: '200px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          alt={label}
          style={{ cursor: 'pointer', width: '150px', height: '150px' }}
          src={imageSrc}
        />
        <input
          type="file"
          accept="image/*"
          name="photo"
          id="upload-photo"
          style={{
            opacity: 0,
            position: 'absolute',
            cursor: 'pointer',
          }}
          onChange={({ target: { files } }) => {
            if (files && files[0]) onChangeHandler(files[0])
          }}
        />
      </div>
      <Snackbar isActive={isActive} message={message} />
    </div>
  );
}

export default connectField<ImageFieldProps>(Image);
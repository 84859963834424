import StatusCard from './StatsCard';
const ProductDashboard = () => {
    return <>
        <div className="row">
            <StatusCard
                figure={'10'}
                label={'No Of SHG\'s'}
                icon={<i className="ni ni-shop text-lg opacity-10" aria-hidden="true"></i>}
                // extraInfo={<span className="text-success text-sm font-weight-bolder">+55%</span>}
            />
            <StatusCard
                figure={'100'}
                label={'No Of Members\'s'}
                icon={<i className="ni ni-single-02 text-lg opacity-10" aria-hidden="true"></i>}
                // extraInfo={<span className="text-success text-sm font-weight-bolder">+55%</span>}
            />
        </div>
    </>
}

export default ProductDashboard
import { useEffect, useState } from 'react';
import http from '../utils/http';
import AutoFormFieldArray from './AutoFormFieldArray';
import { useSelector } from 'react-redux';

const BankDetails = ({ ...props }: any) => {

    const [segment, setSegment] = useState(
        {
            config: {},
            segmentMetas: []
        }
    )
    
    const {tab:{tab}} = useSelector((state:any)=>{
        return state
    })

    useEffect(() => {

        http.get(`segment/${props.config.bankDetailsSegment}`)
            .then(({ data }) => {
                setSegment(data)
            })
            .catch((e) => console.log(e, 'error'))

    }, [props.config.bankDetailsSegment])

    return (
        <>
            {
                tab && (tab==='Members') &&

                <>
                    <AutoFormFieldArray
                        {...props}
                        title='Bank Details'
                        config={segment.config}
                        type='bank'
                        segmentMeta={segment.segmentMetas}
                        memberDetails={props?.member}


                    />
                </>
            }
        </>
    )
}

export default BankDetails;
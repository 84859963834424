import { ValidatedQuickForm } from "uniforms-unstyled"

import { useSelector } from "react-redux"

const getSchema = (segment: object, config: Object, required: any, getDefaultValue: any) => {
  const schema: any = {
    ...segment,
    properties: {
      ...config
    },
    required: getDefaultValue ? [] : required
  }

  return { schema }
}

const convertToDate = (val: string) => {
  return new Date(val)
}

export const buildSchemaForCreate = (segment: any, segmentMetas: any, getDefaultValue: any, segmentMetaData: any, entityType: string, segmentMetaIdToEdit: any) => {  // const [default,setDefault]

  const {tab:{tab}} = useSelector((state:any)=>{
    return state
  })
  const requiredAttributes: any[] = []
  const conditionArr: any[] = []
  let dependsUpon: any = {}
  // console.log("segmentMetaData",segmentMetaData[0])
  const config = segmentMetas.reduce((accData: any, data: any) => {
    const fieldName = data.metaField.name
    const config = data.config[fieldName] || {}
    // const value = segmentMetaData?.find((p: any) => p.segmentMeta?.id === data?.id)?.value
    // console.log("value : ",value)
    //   const value = segmentMetaData?.find((p: any) => 
    //              console.log("segementMeta",p)
    // )

    // console.log("segmentMetaData Address",segmentMetaData[0].address[0])
    let value = ""
    if (segmentMetaData && segmentMetaData[0] && fieldName in segmentMetaData[0] && segmentMetaData[0][fieldName]) {
      value = segmentMetaData[0][fieldName]
    }
    else {
      // console.log("address fieldName :",fieldName)
      if (tab && tab === "Addresses") {
        const foundObject = segmentMetaData? segmentMetaData[0].address.find(
          (obj: any) => obj.id === segmentMetaIdToEdit && obj.hasOwnProperty(fieldName)
        ) : {};

        if (foundObject) {
          value = foundObject[fieldName];
        }
      }
      else if (tab && tab === "Bank Details") {  
        const foundObject = segmentMetaData[0].bank.find((obj: any) => obj.id === segmentMetaIdToEdit && obj.hasOwnProperty(fieldName))
        if (foundObject) {
          value = foundObject[fieldName]
        }
      }

      else if (tab && tab === "Members") {
        if(segmentMetaData?.hasOwnProperty(fieldName)){
          value = segmentMetaData[fieldName]
        }
      }
    }

    const { isRequired, condition, dependsOn, kycRequired, showWhenValueIs, showActionButton, ...rest } = config
    isRequired && requiredAttributes.push(fieldName)
    condition && conditionArr.push({ condition, fieldName })
    if (showWhenValueIs) {
      if (showWhenValueIs === entityType) {
        accData = { ...accData, [fieldName]: { ...rest, default: getDefaultValue && config.type === 'number' ? parseInt(value, 10) : getDefaultValue && config.format === 'date-time' && value ? convertToDate(value) : getDefaultValue && value ? value : undefined } }
      }
    } else {
      accData = { ...accData, [fieldName]: { ...rest, default: getDefaultValue && config.type === 'number' ? parseInt(value, 10) : getDefaultValue && config.format === 'date-time' && value ? convertToDate(value) : getDefaultValue && value ? value : undefined } }
    }
    if (dependsOn) dependsUpon = { ...dependsUpon, [fieldName]: dependsOn }
    return accData
  },{})
  // console.log("config search : ",config)
  return { conditionArr, dependsUpon, schema: getSchema(segment.formBuilderConfig, config, requiredAttributes, getDefaultValue) }
}

export const buildSchemaToEditFieldArray = (segment: any, memberMultiMetas: any, getDefaultValue: any, segmentMetaData: any) => {
  const requiredAttributes: any[] = []
  const config = memberMultiMetas.map((data: any) => {
    return data.segmentMetaDatum.map((d: any) => {
      const fieldName = d.segmentMeta.metaField.name
      const config = d.segmentMeta.config[fieldName] || {}
      const value = d.value
      const { isRequired, ...rest } = config
      if (isRequired) requiredAttributes.push(fieldName)
      return {
        ...rest,
        id: data.id,
        fieldName,
        default: getDefaultValue && config.type === 'number'
          ? parseInt(value, 10)
          : (
            (getDefaultValue && config.format === 'date-time')
              ? convertToDate(value)
              : (
                getDefaultValue
                  ? value
                  : undefined
              )
          )
      }
    })
  })

  return { schema: getSchema(segment.formBuilderConfig, config, requiredAttributes, getDefaultValue) }
}

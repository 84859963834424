import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tab:"SHG Details"
}

export const tabSlice = createSlice({
    name:'tab',
    initialState,
    reducers:{
        currentTab : (state,action)=>{
            state.tab = action.payload
        }
    }
})

export const {currentTab} = tabSlice.actions
export default tabSlice.reducer
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import todoReducer from '../features/test/testSlice'
import productReducer from '../features/product/productSlice'
import segmentReducer from '../features/segment/segmentSlice'
import productFormReducer from '../features/product-form/productFormSlice'
import entitySlice from '../features/entity/entitySlice'
import memberReducer from '../features/members/memberSlice'
import sidebarSlice from '../features/sidebar/sidebarSlice'
import userReducer from '../features/user/userSlice'
import tabSlice from '../features/tab/tabSlice'

export const store = configureStore({
  reducer: {
    todo: todoReducer,
    product: productReducer,
    productForm: productFormReducer,
    entity: entitySlice,
    segment: segmentReducer,
    member: memberReducer,
    sidebar: sidebarSlice,
    user: userReducer,
    tab : tabSlice
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>

import { Children, ReactElement, useEffect } from "react";
import { useForm } from "uniforms";

type DisplayIfProps = {
    children: ReactElement;
    fieldName: string;
    conditionArr: any[];
    fieldToShow: any,
    config: any,
    setFieldToShow: any;
    dependsUpon: any;
    className: any
};

function DisplayIf({ dependsUpon, children, fieldName, conditionArr, fieldToShow, config, setFieldToShow,className }: DisplayIfProps) {

    const uniforms = useForm();

    useEffect(() => {
        let formValue: Record<string, any> = { ...uniforms.model }
        if (!Object.keys(formValue)?.length) formValue = { [dependsUpon[fieldName]]: config[dependsUpon[fieldName]]?.default }

        const values = Object.entries(formValue).filter((x: any) => Object.keys(dependsUpon).find((a: any) => x[0] === dependsUpon[a])).flat();
        if (values?.length) {
            const { condition } = conditionArr.find(c => c.fieldName === dependsUpon[fieldName]) || {};
            const currentFieldValue = formValue[dependsUpon[fieldName]]
            if (condition) {
                const conditionToCheck = values.includes(dependsUpon[fieldName]) && condition[0].visible.includes(fieldName)

                if (currentFieldValue !== condition[0].if.value && conditionToCheck) {
                    if (fieldToShow[fieldName]) {
                        const cloneFields = { ...fieldToShow }
                        delete cloneFields[fieldName];
                        setFieldToShow({ ...cloneFields });
                    }
                }
                else if (currentFieldValue === condition[0].if.value && conditionToCheck) {
                    if (!fieldToShow[fieldName]) {
                        setFieldToShow({ ...fieldToShow, [fieldName]: values[1] })
                    }
                }
            }
        }
    }, [conditionArr, dependsUpon, fieldName, fieldToShow, setFieldToShow, uniforms])

    return fieldToShow[fieldName] ? Children.only(children) : null;

}

export default DisplayIf;





import React, { Profiler } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './assets/css/style.css';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-ui-dashboard.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

const callback = (id: any, phase: any, actualDuration: any, startTime: any, 
  baseDuration: any, commitTime: any, interactions: any) => {
  console.log(
    "id " + id + 
    " startTime " + startTime + 
    " actualDuration " + actualDuration + 
    " baseDuration " + baseDuration + 
    " commitTime " + commitTime + 
    " phase " + phase + 
    " interactions " + interactions
  );
}

root.render(
  // TODO -> commented because of uniforms package issue. Uncomment once it is stable in V4.  
  // <React.StrictMode>
    <Provider store={store}>
      <Profiler id="test" onRender={callback}>
      <App />
      </Profiler>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
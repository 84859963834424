import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import userService from './userService';

const initialState = {
    user: {}
};

//get user
export const getUser = createAsyncThunk("role/validateUser", async () => {
    const user = await userService.getUserDetails();
    return user.data;
})


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.user = {}
        }
    },
    extraReducers: (builder) => {
        builder
            // get user
            .addCase(getUser.pending, (state) => {
                state.user = {}
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state) => {
                state.user = {};
            })
    }
})

export const { reset } = userSlice.actions;

export default userSlice.reducer;
import { useEffect, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faDownload, faEdit, faEye, faClose, faFileContract, faIndianRupee, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import Segments from '../components/Segments';
import '../components/segment.css'
import './productComplete.css'
import http from '../utils/http';
import { useParams } from 'react-router-dom';
import Accordion from '../components/Accordion';
import Loader from '../components/Loader';
import SubmissionForReview from '../components/SubmissionForReview';
import { AppDispatch } from '../app/store';
import { getEntity, updateEntity } from '../features/entity/entitySlice';
import { getSegments } from '../features/segment/segmentSlice';
import logo from '../assets/img/givfin-white.svg'
import Iframe from '../components/Iframe';
import { useSnackbar } from '../hooks/useSnackbar';
import Snackbar from '../components/Snackbar';
import { useQuery } from '@tanstack/react-query';
// import KeyValue from '../components/KeyValue';

type EntityDetailsViewProps = {
    productForm: any
}

declare const window: Window &
    typeof globalThis & {
        Digio: any
    }

const EntityDetailsView = ({ productForm }: EntityDetailsViewProps) => {
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [submissionModal, setSubmissionModal] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [disableFields, setDisableField] = useState(true);
    const [displayContent, setDisplayContent] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [iframe, setIframe] = useState()
    const { isActive, openSnackBar } = useSnackbar()
    const [message, setMessage] = useState('')

    const { segment: { segments }, user: { user: currentUser } } = useSelector((state: any) => {
        return state
    })


    const dispatch = useDispatch<AppDispatch>();
    const { permissions: { action: { loanApplication, approval, submitForReview } } } = productForm.config || {}

    const { entityId } = useParams()
    useEffect(() => {
        setLoading(true)
        dispatch(getSegments())
    }, [entityId])

    const getOneEntity = async () => {
        const { data } = await http.get(`/getEntity/${entityId}`)
        return data
    }

    const { data: entity, isLoading: isLoadingState } = useQuery({ queryKey: ['eachEntityDetails'], queryFn: getOneEntity })

    const segmentMetaDatas = useMemo(
        // () => (entity ? [...entity.entityMeta.segmentMetaDatum, ...(entity.entityMultiMetas?.map((a: any) => a.segmentMetaDatum) || [])] : []),
        () => (entity ? [entity] : []),
        [entity]
    )

    const arr: any[] = []
    // const getVal = (segmentMetaData: any[], additionalFlag = null) => {
    //     segmentMetaData.forEach((a: any) => {

    //         const index = arr.findIndex((p: any) => p.title === a.segmentMeta?.segment?.name)

    //         if (index !== -1) {
    //             if (arr[index].values) {
    //                 if (arr[index].multiple && additionalFlag) {
    //                     const itemIndexFound = arr[index].values.findIndex((ai: any) => ai.multiMetaId === additionalFlag);
    //                     if (itemIndexFound !== -1) {
    //                         const multiMetaItemFound = arr[index].values[itemIndexFound].values.find((ai: any) => ai.key === a.segmentMeta?.metaField.displayName);
    //                         if (!multiMetaItemFound) {
    //                             arr[index].values[itemIndexFound].values.push({ key: a.segmentMeta?.metaField.displayName, value: a.value })
    //                         }
    //                     } else {
    //                         arr[index].values.push({ multiMetaId: additionalFlag, values: [{ key: a.segmentMeta?.metaField.displayName, value: a.value }] })
    //                     }
    //                 } else {
    //                     const itemFound = arr[index].values.find((ai: any) => ai.key === a.segmentMeta?.metaField.displayName)
    //                     if (!itemFound) arr[index].values.push({ key: a.segmentMeta?.metaField.displayName, value: a.value })
    //                 }
    //             }
    //         } else {
    //             const { segmentMeta } = a
    //             if (segmentMeta) {
    //                 const { segment: { id, config: { segmentConfig: { multiple } } } } = segmentMeta
    //                 if (segmentMeta) {
    //                     if (multiple && additionalFlag) {
    //                         arr.push({ id, title: a.segmentMeta?.segment.name, multiple, values: [{ multiMetaId: additionalFlag, values: [{ key: a.segmentMeta?.metaField.displayName, value: a.value }] }] })
    //                     } else {
    //                         arr.push({ id, title: a.segmentMeta?.segment.name, multiple, values: [{ key: a.segmentMeta?.metaField.displayName, value: a.value }] })
    //                     }

    //                 }
    //             }
    //         
    //     })
    // }


    const memberDetails = segments && segments?.find((d: any) => d.config.type === 'members' && !d.config.segmentConfig?.multiple)


    // const getKeyValues = () => {
    //     if (entity) {
    //         getVal(entity.entityMeta.segmentMetaDatum);
    //         entity.entityMultiMetas?.map((a: any) => getVal(a.segmentMetaDatum, a.id))
    //         getVal((entity.verification || []))
    //     }
    //     // if (memberDetails) {
    //     //     getVal(memberDetails.segmentMetas)
    //     // }
    //     return arr;
    // }

    // const res = getKeyValues();

    // const shgType: any = entity && entity.entityMeta.segmentMetaDatum.find(
    //     (d: any) => d.segmentMeta.metaField.name === 'shgType',
    // )

    const shgType = entity?.entityType

    let keyString = ""
    let resultContent: { title: string, values: any }[] = [];
    segments?.forEach((segment: any) => {
        if (segment?.config?.type?.toLowerCase() === localStorage.getItem('activeMenu')?.toLowerCase()) {
            let segmentValues: any = {};
            segment?.segmentMetas?.forEach((segmentMeta: any) => {
                keyString = Object.keys(segmentMeta.config)[0];
                if (entity && keyString in entity) {
                    segmentValues = { ...segmentValues, [keyString]: entity[keyString] };
                }
            })
            if (Object.keys(segmentValues).length !== 0) {
                resultContent.push({ title: segment?.name, values: segmentValues });
            }
        }
    })

    //pushing the address to resultContent
    if (entity?.address?.length) {
        const correctAddress: any[] = []
        entity?.address?.forEach((Address: any) => {
            const { id, createdAt, updatedAt, ...addressField } = Address
            correctAddress.push(addressField)
        })
        resultContent.push({ title: 'Addresses', values: correctAddress })
    }

    //pushing the member details
    if (entity?.members?.length) {
        const memberDetails: any[] = []
        entity?.members?.forEach((member: any) => {
            const { id, memberName, panNumber, mobileNo } = member
            // const {createdAt,updatedAt,...memberDetail} = member
            memberDetails.push({ id, memberName, panNumber, mobileNo })
            // memberDetails.push(memberDetail)
        })
        resultContent.push({ title: 'Members', values: memberDetails })
    }

    if (entity?.bank?.length) {
        const bankDetails: any[] = []
        entity?.bank?.forEach((bank: any) => {
            const { id, accountCategory, accountType, accountHolderName, accountNo, ifscCode, bankName, branchName, swiftCode } = bank
            bankDetails.push({ id, accountCategory, accountType, accountHolderName, accountNo, ifscCode, bankName, branchName, swiftCode })
        })
        resultContent.push({ title: 'Bank Details', values: bankDetails })
    }



    // const freshLinkage = shgType?.value === 'fresh' ? true : false;
    // const repeatLinkage = shgType?.value === 'mature' ? true : false;

    const updateStatus = async (responseMessage: string, isEsign = false) => {
        // if (responseStatus === "") return;
        try {
            setLoading(true)
            const { data } = await http.patch(`/entity/${entityId}`, {
                transitionStatus: isEsign ? 'ENACH PENDING' : responseStatus,
                comments: responseMessage
            })
            if (data) {
                dispatch(updateEntity({
                    ...entity,
                    transitionStatus: responseStatus,
                    comments: responseMessage
                }))
                setLoading(false)
                if (submissionModal) setSubmissionModal(false)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    // const saveLinkageData = async (entityId: number, verificationData: any) => {
    //     const { data } = await http.post(`/verification`, {
    //         kycDetails: verificationData,
    //         entityId,
    //         verified: true,
    //         config: {
    //             linkage: shgType?.value
    //         }
    //     })

    //     console.log('kyc verification data', data)
    // }

    const downloadHandler = async () => {
        try {
            setLoading(true)
            const response: any = await http.get(`/entity/download/${entityId}`)
            if (response) {
                const decodedBuffer = new Blob([new Uint8Array(response.data.data).buffer]);
                const url = URL.createObjectURL(decodedBuffer);
                const link = document.createElement('a');
                link.href = url;
                link.download = `SHG-${entityId}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false)
            } else {
                setLoading(false)
                console.error('Error downloading PDF:', response.statusText);
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        }
    }


    function loadScript(src: any, callback: any) {
        const sdkAdded = document.getElementById('digio');
        if (!sdkAdded) {
            // console.log('first',callback())
            const script = document.createElement('script');
            script.setAttribute('src', src);
            script.setAttribute('id', 'Digio');
            script.addEventListener('load', callback);
            document.head.append(script);
        }
    }

    const digioKycOptions = () => ({
        environment: process.env.REACT_APP_DIGIO_ENV,
        theme: {
            primaryColor: '#AB3498',
            secondaryColor: '#000000'
        },
        //   logo: require('../assets/img/givfin-white.svg'),
        logo: logo || 'https://cdn.pixabay.com/photo/2015/03/17/02/01/cubes-677092__480.png',
        callback: (response: any) => {
            if (response && response.error_code) {
                console.log('response error', response?.message)
                return;
            }
            console.log(`response -> ${response}`, response);
        },
        is_iframe: true,
    });

    const eSignHandler = async () => {
        initDigio({ logo: 'https://cdn.pixabay.com/photo/2015/03/17/02/01/cubes-677092__480.png', documentId: 12, number: 8762220637 })
    }

    const getId = async () => {
        setLoading(true)
        const { data } = await http.post(`/entity/esign/${entityId}`)
        if (data?.id) setLoading(false)
        return data.id
    }


    async function initDigio({ logo, documentId, number, type = 'esign' }: any) {
        const digoOptions = {
            logo,
            is_iframe: true,
            callback: async (_digio: any) => {
                if (_digio.error_code === "CANCELLED") {
                    console.log("Flow cancelled by user");
                    setLoading(false)
                    return;
                }
                if (_digio.error_code !== undefined) {
                    setLoading(false)
                    throw new Error(_digio.message);
                }
                if (_digio.message === "Signed Successfully") {
                    await updateStatus(entity.comments, true)
                }
            }
        };
        let id
        if (type === 'esign') id = await getId()

        const digioInstance = new window.Digio(digoOptions);
        console.log(digioInstance, id, 'digioInstance')
        digioInstance.init();
        digioInstance.submit(id || documentId, number);
    }

    function open(url: any) {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }


    async function triggerEnach() {
        // console.log('triggerEnach')
        setLoading(true)
        try {
            const { data } = await http.post(`/entity/createMandate/${entityId}`);
            // console.log('data is for enach', data)
            if (data.status === 'OK' && data?.subReferenceId) {
                open(data.authLink);
                setLoading(false)
                // setResponseStatus("SANCTIONED")
            }
            else if (data.subCode === '400') {
                setLoading(false)
                setMessage(data.message)
                openSnackBar()
            }
        } catch (e) {
            //   SnackBar.showError(e?.response?.data?.message || e);
        }
    }

    // async function esignAgreement() {
    //     const { data } = await http.get(`/entity/viewAgreement/${entityId}`);
    //     console.log('data is', data)
    //     if (data) {
    //         // document.body.append(data)
    //         setIframe(data)
    //     }
    // }


    return (
        <>
            <div className='px-0 mx-4'>
                {
                    isLoadingState && <Loader />
                }
                {
                    entity && entity.transitionStatus &&
                    <div>
                        {
                            entity.comments && entity.comments !== "" &&
                            <div className="alert alert-warning">
                                <span className="alert-text">
                                    <strong>Last Comment: </strong>{entity.comments}
                                </span>
                            </div>
                        }
                    </div>
                }
                <div style={{ marginTop: '1rem' }}>
                    {loanApplication.edit.includes(currentUser?.role) &&
                        <button style={{ border: 'none', cursor: 'pointer' }}
                            className='btn btn-sm btn-dark' onClick={() => {
                                setDisableField(!disableFields)
                                localStorage.setItem('currentEntity', JSON.stringify({ id: entity.id, shgType: shgType.value }))
                            }}>
                            <>
                                {disableFields ? 'Edit' : 'View'}
                                <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={disableFields ? faEdit : faEye} />
                            </>
                        </button>
                    }

                    <button disabled={initiateDownload} style={{ border: 'none', marginLeft: '1rem', cursor: 'pointer' }}
                        className='btn btn-sm btn-info' onClick={() => {
                            downloadHandler()
                            setInitiateDownload(!initiateDownload)
                            // setDisplayContent(true);
                            // setTimeout(() => {
                            //     window.print()
                            // }, 1000)
                        }}>
                        <>
                            {initiateDownload ? 'Downloaded' : 'Download'}
                            <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faDownload} />
                        </>
                    </button>
                    


                    {entity && ["ESIGN PENDING"].includes(entity.transitionStatus) && <button
                        style={{ border: 'none', marginLeft: '1rem', cursor: 'pointer' }}
                        className='btn btn-sm bg-gradient-primary'
                        onClick={() => {
                            eSignHandler()
                        }}
                    >
                        <>
                            Click to complete ESign
                            <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faFileContract} />
                        </>
                    </button>}


                    {entity && ['ENACH PENDING'].includes(entity.transitionStatus) &&
                        <button
                            style={{ border: 'none', marginLeft: '1rem', cursor: 'pointer' }}
                            className='btn btn-sm bg-gradient-success'
                            onClick={() => {
                                triggerEnach()
                            }}
                        >
                            <>
                                Click to complete ENACH
                                <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faHandshake} />
                            </>
                        </button>
                    }
                </div>

                {
                    entity && entity.transitionStatus && ["initiated", "rejected"].includes(entity.transitionStatus) && submitForReview.edit.includes(currentUser?.role) &&
                    <button disabled={initiateDownload}
                        // style={{ border: 'none', cursor: 'pointer' }}
                        className='btn btn-sm bg-gradient-warning'
                        onClick={() => {
                            setSubmissionModal(true)
                            setResponseStatus("in review")
                            entity.transitionStatus = "in review"
                        }}>
                        <>
                            Submit For Review
                            <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faCheck} />
                        </>
                    </button>
                }

                {
                    approval.edit.includes(currentUser?.role) && <>
                        {
                            entity && entity.transitionStatus && entity.transitionStatus === "in review" &&
                            <>
                                <button disabled={initiateDownload}
                                    style={{ border: 'none', cursor: 'pointer' }}
                                    className='btn btn-sm bg-gradient-success'
                                    onClick={() => {
                                        setSubmissionModal(true)
                                        setResponseStatus("ESIGN PENDING")
                                    }}>
                                    <>
                                        Approve
                                        <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faCheck} />
                                    </>
                                </button>
                                <button disabled={initiateDownload}
                                    style={{ border: 'none', marginLeft: '1rem', cursor: 'pointer' }}
                                    className='btn btn-sm bg-gradient-danger'
                                    onClick={() => {
                                        setSubmissionModal(true)
                                        setResponseStatus("REJECTED")
                                    }}>
                                    <>
                                        Reject
                                        <FontAwesomeIcon style={{ paddingLeft: '1rem' }} icon={faClose} />
                                    </>
                                </button>
                            </>
                        }
                    </>
                }

                {iframe && <Iframe iframe={iframe} />}

                <SubmissionForReview
                    visible={submissionModal}
                    onSubmit={(responseMessage: string) => {
                        updateStatus(responseMessage)
                    }}
                    onClose={() => {
                        setSubmissionModal(false)
                        setResponseStatus("")
                    }}
                />

                {
                    disableFields ?
                        <div className='card' style={{ padding: '1rem' }}>
                            <div className="accordion-flush">
                                <div className="container">
                                    <>
                                        {/* {entity && entity.id && res.map(({ id, title, values }, ind) => (
                                            <Accordion user={currentUser} productForm={productForm} key={ind} id={id} title={title} entity={entity} memberDetails={memberDetails} content={values} displayContent={displayContent} />
                                        ))} */}
                                        {/* {
                                                entity &&
                                                entity.id 
                                                &&
                                                segments.map((p: any,ind: number)=>(
                                                    
                                                    if(p.config?.segmentConfig?.type === localStorage.getItem('activeMenu').toLowerCase()){

                                                        <Accordion user={currentUser} productForm={productForm} key={ind} id={p.id} title={p.name} entity={entity} memberDetails={memberDetails} content={"hi"} displayContent={displayContent} />
                                                    }
                                                ))
                                                // <KeyValue entity={entity} />
                                                // segments

                                        } */}
                                        {
                                            resultContent.map((p: any, ind: number) => {
                                                if (!Object.values(p?.values).every(value => value === null)) {
                                                    return (
                                                        <Accordion
                                                            user={currentUser}
                                                            productForm={productForm}
                                                            key={ind}
                                                            id={p.id}
                                                            entity={entity}
                                                            // memberDetails={entity}
                                                            content={p}
                                                            displayContent={displayContent}
                                                        />
                                                    );
                                                }

                                            })
                                        }

                                        {/* {
                                        entity && entity.id && entity.transitionStatus === "COMPLETED" && ruleEngineData &&
                                        <>
                                            <h5 style={{ marginTop: '2rem' }} className="accordion-button border-bottom font-weight-bold collapsed" id="headingOne">
                                                {`${freshLinkage ? 'Fresh' : 'Repeat'} Linkage`}
                                            </h5>
                                            <Linkages linkage={freshLinkage} repeatLinkage={repeatLinkage} onSave={saveLinkageData} />
                                        </>
                                    } */}
                                    </>
                                </div>
                            </div>
                        </div>
                        : <Segments setLoading={setLoading} edit segmentMetaData={segmentMetaDatas} productForm={productForm} />
                }
            </div>
            <Snackbar isActive={isActive} message={message} />
        </>
    )
}

export default EntityDetailsView;